import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Gender } from 'src/components/registration/signUp/constants/sign-up.enum';

import { PhotoEntity, UserWithProfiles } from '../services/profile/dto/get-user-profile.response';

interface IUserSlice extends UserWithProfiles {
  countryCode?: string | null;
  longitudeByIp: number | null;
  latitudeByIp: number | null;
}

const initialState: IUserSlice = {
  id: '',
  firstName: '',
  lastName: '',
  gender: Gender.Female,
  dateOfBirth: '',
  emails: [],
  phoneNumbers: [],
  addresses: [],
  photos: [],
  avatarId: '',
  profiles: [],
  twoFactorSecret: false,
  keys: [],
  biometrics: false,
  countryCode: null,
  longitudeByIp: null,
  latitudeByIp: null,
  nickname: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<UserWithProfiles>) {
      Object.assign(state, payload);
    },
    setPhoto(state, { payload }: PayloadAction<PhotoEntity>) {
      state.photos = [...state.photos, payload];
      state.avatarId = payload.id;
    },
    setCoordinates(
      state,
      {
        payload,
      }: PayloadAction<{
        countryCode: string;
        longitude: number;
        latitude: number;
      }>,
    ) {
      state.countryCode = payload.countryCode;
      state.longitudeByIp = payload.longitude;
      state.latitudeByIp = payload.latitude;
    },
    removeUser: (state) => {
      const clearState = {
        ...initialState,
        latitudeByIp: state.latitudeByIp,
        longitudeByIp: state.longitudeByIp,
        countryCode: state.countryCode,
      };

      return clearState;
    },
    setTwoFactorSecret(state, { payload }: PayloadAction<boolean>) {
      state.twoFactorSecret = payload;
    },
  },
});

export const { setUser, setPhoto, removeUser, setTwoFactorSecret, setCoordinates } =
  userSlice.actions;

export default userSlice.reducer;
