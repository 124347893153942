import React, { useEffect, useRef } from 'react';
import * as lottie from 'lottie-web';

import styles from './styles.module.scss';

const Loader = () => {
  const loaderRef = useRef<HTMLDivElement>(null);
  const path = '/assets/animation/Anna_loader.json';

  useEffect(() => {
    if (!path || !loaderRef.current) {
      return;
    }

    const lottieAnimation = (lottie as any).loadAnimation({
      container: loaderRef.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path, // the path to the animation json
    });

    lottieAnimation.play();

    return () => {
      lottieAnimation.destroy();
    };
  }, [path]);

  return (
    <div className={styles.busyIndicator}>
      <div className={styles.mask}></div>
      <div className={styles.loaderContainer}>
        <div ref={loaderRef}></div>
      </div>
    </div>
  );
};

export default Loader;
