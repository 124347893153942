import { PaletteType } from '../interfaces/PaletteType';

import styles from '../styles.module.scss';

export const palettes: Record<PaletteType, string> = {
  primary: `${styles.palettesPrimary}`,
  secondary: `${styles.palettesSecondary}`,
  pink: `${styles.palettesPink}`,
  purpose: `${styles.palettesPurpose}`,
  green: `${styles.palettesGreen}`,
  beige: `${styles.palettesBeige}`,
  lilac: `${styles.palettesLilac}`,
  darkGray: `${styles.palettesDarkGray}`,
  clearBeige: `${styles.palettesClearBeige}`,
  clear: `${styles.palettesClear}`,
};
