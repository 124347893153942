import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PagesEnums } from "../../../types/pages.enums";

const PagesSteps = [PagesEnums.Employees, PagesEnums.Lendings, PagesEnums.Tests];

interface IInitialState {
  currentPage: PagesEnums;
  pages: PagesEnums[];
}

const initialState: IInitialState = {
  currentPage: PagesEnums.Employees,
  pages: PagesSteps,
};

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPage: (state: IInitialState, { payload }: PayloadAction<PagesEnums>) => {
      state.currentPage = payload;
    },
    resetPages: (state: IInitialState) => {
      state.currentPage = state.pages[0]!;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase(() => {});
  },
});
const { reducer } = pagesSlice;
export default reducer;
export const { setPage, resetPages } = pagesSlice.actions;
