// ========================== redux ==========================
import { createAsyncThunk } from "@reduxjs/toolkit";
// ========================== types ==========================

// ========================== store ==========================
import $api from "../../../api/api";
import { ILendingWithConnections } from "./types/LendingWithConnections.dto";
import { AnswerCreateDto } from "./types/answer-create.dto";
import { AnswerDto } from "./types/answer-dto.type";
import { CurrentTestDto } from "./types/current-test.dto";
import { LendingDto } from "./types/lending.dto";
import { QuestionCreateDto } from "./types/question-create.dto";
import { IQuestionDeleteDto } from "./types/question-delete.dto";
import { QuestionWithAnswersDto } from "./types/question-dto.type";
import { updateQuestionDto } from "./types/question.dto";
import { TestCreateDto } from "./types/test-create.dto";
import { TestDto } from "./types/test-dto.type";
import { TestWithResultDto } from "./types/test-with-results.dto";
import { UpdateAnswerDto } from "./types/update-answer.dto";
import { UpdateTestDto } from "./types/update-test.dto";

// ============ GET ALL TESTS ============ //
export const getAllTests = createAsyncThunk<CurrentTestDto[]>(
  "tests/",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get("/tests/?isActive=true");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET ALL LENDINGS ============ //
export const getAllLendings = createAsyncThunk<LendingDto[]>(
  "tests/lendings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get("/tests/lendings");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET TEST BY ID ============ //
export const getSnapshotById = createAsyncThunk<TestDto, string>(
  "tests/snapshot/snapshotId",
  async (snapshotId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/snapshot/${snapshotId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ UPDATE TEST ============ //
export const postUpdateTest = createAsyncThunk<
  CurrentTestDto[],
  { testId: string; data: UpdateTestDto }
>("/update/:testId", async ({ testId, data }, { rejectWithValue }) => {
  try {
    const response = await $api.patch(`/tests/update/${testId}`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message as string);
  }
});

// ============ GET FULL TEST BY ID ============ //
export const getFullTest = createAsyncThunk<TestWithResultDto, string>(
  "tests/test/:testId",
  async (testId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/test/${testId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ UPDATE QUESTION BY ID ============ //
export const patchUpdateQuestion = createAsyncThunk<
  QuestionWithAnswersDto,
  { questionId: string; data: updateQuestionDto }
>("/tests/update/question/questionId", async ({ questionId, data }, { rejectWithValue }) => {
  try {
    const response = await $api.patch(`/tests/update/question/${questionId}`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message as string);
  }
});

// ============ GET QUESTION BY ID ============ //
export const getQuestionById = createAsyncThunk<QuestionWithAnswersDto, string>(
  "/tests/question-with-answers/questionId",
  async (questionId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/question-with-answers/${questionId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET QUESTION BY ID WITHOUT SLICE ============ //
export const getQuestionByIdWithoutSlice = createAsyncThunk<QuestionWithAnswersDto, string>(
  "tests/question-with-answers",
  async (questionId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/question-with-answers/${questionId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ UPDATE ANSWER BY ID ============ //
export const patchUpdateAnswer = createAsyncThunk<
  QuestionWithAnswersDto,
  { answerId: string; data: UpdateAnswerDto }
>("/tests/update/answer/answerId", async ({ answerId, data }, { rejectWithValue }) => {
  try {
    const response = await $api.patch(`/tests/update/answer/${answerId}`, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message as string);
  }
});

// ============ GET ANSWER BY ID ============ //
export const getAnswerById = createAsyncThunk<AnswerDto, string>(
  "/tests/answer/answerId",
  async (answerId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/answer/${answerId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ DELETE ANSWER BY ID ============ //
export const deleteAnswerById = createAsyncThunk(
  "/tests/answer/delete/answerId",
  async (answerId: string, { rejectWithValue }) => {
    try {
      console.log(answerId);
      const response = await $api.delete(`/tests/answer/${answerId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ POST CREATE TEST ============ //
export const createTest = createAsyncThunk(
  "/tests/create",
  async (data: TestCreateDto, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/tests/create`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ POST CREATE ANSWER ============ //
export const createAnswer = createAsyncThunk(
  "/tests/answer/create",
  async (data: AnswerCreateDto, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/tests/answer/create`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ POST CREATE QUESTION ============ //
export const createQuestion = createAsyncThunk(
  "/tests/question/create",
  async (data: QuestionCreateDto, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/tests/question/create`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ DELETE QUESTION BY ID ============ //
export const deleteQuestionById = createAsyncThunk(
  "/tests/question/delete/questionId",
  async (data: IQuestionDeleteDto, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/tests/question/delete`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET LENDING BY ID ============ //
export const getLendingById = createAsyncThunk<ILendingWithConnections, number>(
  "/tests/lending/lendingId",
  async (lendingId: number, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/lending/${lendingId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);
