import React, {
  ChangeEvent,
  CSSProperties,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { forwardRef } from 'react';
import { clsx as cn } from 'clsx';

import { getInitialText } from 'src/components/lips/customInput/helpers/hocs/constants/getInitialText';
import { initialClassNames } from 'src/components/lips/customInput/helpers/hocs/constants/initialClassNames';
import { MaskInputProps } from 'src/components/lips/customInput/Mask';

import { HeightType } from '../../interfaces/HeightType';
import { PaletteType } from '../../interfaces/PaletteType';
import { getClassNames } from '../config';
import Wrapper from '../Wrapper';

type LabelProps = {
  error?: string;
  label: string;
  errorClassName?: string;
  touched?: boolean;
  noHeading?: boolean;
  noBackground?: boolean;
  noBorderBottom?: boolean;
  borderBottomLabel?: boolean;
  fixedLabel?: boolean;
  withIcon?: boolean;
  palette?: PaletteType;
  height?: HeightType;
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  maskPlaceholder?: string;
  inputWrapperClassName?: string;
  containerClassNames?: string;
  labelClassName?: string;
}

interface IStylesProps {
  wrapperStyle?: CSSProperties;
  lableStyle?: CSSProperties;
}

type WithWrapperProps =
  | (MaskInputProps & LabelProps & IStylesProps)
  | (InputProps & LabelProps & IStylesProps);

function withWrapper<T>(Component: React.ComponentType<T & WithWrapperProps>) {
  return forwardRef<any, T & WithWrapperProps>((props, ref) => {
    const {
      value,
      touched,
      onChange,
      noHeading,
      noBackground,
      noBorderBottom,
      borderBottomLabel,
      fixedLabel,
      required,
      defaultValue,
      maskPlaceholder,
      label,
      error,
      errorClassName,
      withIcon,
      containerClassNames,
      inputWrapperClassName,
      labelClassName,
      palette,
      height,
      wrapperStyle,
      lableStyle,
      ...remainingProps
    } = props;

    const [text, setText] = useState<string | undefined | null>(
      getInitialText({ defaultValue, maskPlaceholder }),
    );
    const [localTouched, setLocalTouched] = useState(touched);
    const [{ containerClassName, ...wrapperClasses }, setWrapperClasses] =
      useState(initialClassNames);

    useEffect(() => {
      setWrapperClasses(
        getClassNames({
          text,
          label,
          error,
          noHeading,
          fixedLabel,
          noBackground,
          noBorderBottom,
          borderBottomLabel,
          withIcon,
          touched: localTouched,
          labelClassName,
        }),
      );
    }, [text, error, noHeading, noBorderBottom, localTouched]);

    useEffect(() => {
      if (typeof value !== 'undefined') {
        setText(value as string);
      }
    }, [value]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value: eValue } = e.currentTarget;
        setText(eValue);
        setLocalTouched(true);
        return onChange && onChange(e);
      },
      [onChange],
    );

    return (
      <Wrapper
        inputWrapperClassName={inputWrapperClassName}
        text={text}
        label={label}
        error={error}
        errorClassName={errorClassName}
        required={required}
        touched={localTouched}
        palette={palette}
        height={height}
        containerClassName={cn(containerClassName, containerClassNames)}
        {...wrapperClasses}
        wrapperStyle={wrapperStyle}
        lableStyle={lableStyle}
      >
        <Component
          onChange={handleChange}
          value={text}
          ref={ref}
          {...(remainingProps as T & LabelProps & InputProps)}
        />
      </Wrapper>
    );
  });
}

export default withWrapper;
