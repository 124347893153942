import * as yup from 'yup';

export const passwordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required('signUpModal.passwordValidation')
    .min(5, 'signUpModal.passwordLength'),
  passwordConfirmation: yup
    .string()
    .required('signUpModal.passwordValidation')
    .oneOf([yup.ref('password'), ''], 'signUpModal.confirmPasswordValidation'),
});
