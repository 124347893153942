import type { FC } from 'react';
import React from 'react';
import { Formik } from 'formik';

import { useLoginMutation } from 'src/store/services/auth/auth';

import { initFormikValuesSignIn } from './form-model/init-values-sign-in';
import { InitFormikValuesSignIn } from './form-model/sign-in.interfaces';
import { validationSchemaSignIn } from './form-model/validation-schema-sign-in';
import { MainScreenSignIn } from './forms/MainScreenSignIn';

import styles from './styles.module.scss';
import imgSignUp from 'src/assets/images/signIn-origin.png';
import { useNavigate } from 'react-router-dom';

interface ISignInProps {
  createNewAccountHandler: VoidFunction;
  forgotPasswordHandler: VoidFunction;
  isReversed?: boolean;
}

const SignIn: FC<ISignInProps> = ({
  createNewAccountHandler,
  forgotPasswordHandler,
  isReversed,
}) => {
  const navigate = useNavigate();
  const [login, { isLoading, isError }] = useLoginMutation();
  const handleSubmit = async (val: InitFormikValuesSignIn) => {
    const isLogin = await login(val);

    if ('data' in isLogin && (isLogin.data as any).message) {
      navigate(`/main/users`);
    }
  };

  return (
    <div className={styles.container}>
      {/*{(isLoading || isLoadingProfile || isLoadingUser) && <Loader />}*/}
      <div className={styles.formRegistration}>
        <img src={imgSignUp} alt="imgSignUp" className={styles.imgSignUp} />
        <Formik
          className={styles.container}
          validateOnMount
          initialValues={initFormikValuesSignIn}
          validationSchema={validationSchemaSignIn}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <MainScreenSignIn
              isReversed={isReversed}
              isError={isError}
              isValid={formik.isValid}
              createNewAccountHandler={createNewAccountHandler}
              forgotPasswordHandler={forgotPasswordHandler}
            />
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignIn;
