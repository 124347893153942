import { FC } from 'react';
import React from 'react';
import { Form, Formik } from 'formik';

import { useAppSelector } from 'src/hooks/reduxHook';
import { getSignUp } from 'src/store/selectors/signUp';

import ErrorViewerPasswordForm from './components/errorViewer/ErrorViewerPasswordForm';
import PasswordField from './components/passwordFields/PasswordFiels';
import { passwordFormSchema } from './form-model/password-schema-sign-up';
import { useSubmitLastStep } from './hooks/submit-last-step.hook';

import styles from './styles.module.scss';

interface ISignUpProps {
  setIsOpen: (val: boolean) => void;
  isReversed?: boolean;
}

const PasswordForm: FC<ISignUpProps> = ({ isReversed }) => {
  const { onSubmit } = useSubmitLastStep();
  const { passwordInfo } = useAppSelector(getSignUp);
  return (
    <Formik
      initialValues={{ password: '', passwordConfirmation: '' }}
      validationSchema={passwordFormSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <div className={styles.mainContainer}>
            <PasswordField isReversed={isReversed} />
            {passwordInfo.alertError && <ErrorViewerPasswordForm error={passwordInfo.alertError} />}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordForm;
