import { FC } from 'react';
import React from 'react';
import { Form, Formik } from 'formik';

import { useAppSelector } from 'src/hooks/reduxHook';
import { getSignUp } from 'src/store/selectors/signUp';

import ErrorViewerVerificationForm from './components/errorViewer/ErrorViewerVerificationForm';
import VerificationField from './components/verificationFields/VerificationField';
import { verificationFormSchema } from './form-model/validation-schema-sign-up';
import { useSubmitWhatsapp } from './hooks/submit-whatsapp.hook';
import { IStepOnePayload } from './interface/step-one-payload.interface';

import styles from './styles.module.scss';

interface ISignUpProps {
  isOpen: boolean;
  initialValues: IStepOnePayload;
  setActiveStep: (val: number) => void;
  isReversed?: boolean;
}

const VerificationForm: FC<ISignUpProps> = ({
  isOpen,
  initialValues,
  setActiveStep,
  isReversed,
}) => {
  const { verificationInfo } = useAppSelector(getSignUp);
  console.log('verificationInfo', verificationInfo);

  const { whatsappSubmit } = useSubmitWhatsapp({
    isOpen,
    setActiveStep,
    error: verificationInfo.alertError || '',
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={verificationFormSchema}
      onSubmit={whatsappSubmit}
    >
      {() => (
        <Form>
          <div className={styles.mainContainer}>
            <VerificationField alertError={verificationInfo.alertError} isReversed={isReversed} />
            {verificationInfo.alertError && (
              <ErrorViewerVerificationForm error={verificationInfo.alertError} />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VerificationForm;
