// ========================== react ==========================
import React, { FC, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { decodeToken } from 'react-jwt';

// ========================== components ==========================
import FallbackComponent from './components/fallback.comp';

// ========================== enum ==========================
import { UserRoles } from './types/user-roles.enum';
import SignUpPage from './app/pages/SignUpPage/signUp.page';
import HomePage from './app/pages/HomePage/home.page';

interface User {
  roleType: UserRoles;
}

const isAllowed = () => {
  // const token = window.localStorage.getItem("token");
  // if (token) {
  //   const user: User | null = decodeToken(token);
  //   if (user !== null && user.roleType !== UserRoles.user) return true;
  // }
  return true;
};

// ======= private route ======= //
const PrivateRoute: FC<{ element: any }> = ({ element: Element }) => {
  return isAllowed() ? (
    <Suspense fallback={<FallbackComponent />}>
      <Element />
    </Suspense>
  ) : (
    <Navigate to={'/'} />
  );
};

// ======= public route ======= //
const PublicRoute: FC<{ element: any }> = ({ element: Element }) => (
  <Suspense fallback={<FallbackComponent />}>
    <Element />
  </Suspense>
);

// ======= pages ======= //
const LoginPage = React.lazy(() => import('./app/store/login/index'));
const MainPage = React.lazy(() => import('./app/index'));

const AppRoutes = () => {
  return (
    <Routes>
      {/* PUBLIC */}
      <Route path={'/signUp'} element={<PublicRoute element={SignUpPage} />} />
      <Route path={'/signIn'} element={<PublicRoute element={LoginPage} />} />

      <Route path={'/'} element={<PublicRoute element={HomePage} />} />

      {/* PRIVATE */}
      <Route path={'main/*'} element={<PrivateRoute element={MainPage} />} />

      {/* DEFAULT */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
