import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';

import Card from 'src/components/lips/card';
import CustomInput from 'src/components/lips/customInput';

import type { InitFormikValuesSignIn, InitValuesSignIn } from '../form-model/sign-in.interfaces';

import styles from '../styles.module.scss';

export interface ISignIpFormProps {
  fields: InitValuesSignIn;
  isReversed?: boolean;
}

export const SignInFields: FC<ISignIpFormProps> = ({ fields, isReversed }) => {
  const { values } = useFormikContext<InitFormikValuesSignIn>();

  const { t } = useTranslation();

  return (
    <div className={styles.inputWrapper}>
      <Field name={fields.identifier.name} id={fields.identifier.name}>
        {({
          field,
          meta,
        }: FieldProps<{
          emailOrPhone: string;
        }>) => (
          <CustomInput
            variant="default"
            label={t(fields.identifier.label)}
            key={fields.identifier.name}
            name={fields.identifier.name}
            id={fields.identifier.name}
            value={values.identifier}
            onBlur={field.onBlur}
            touched={meta.touched}
            error={t(meta.error as string) || ''}
            onChange={field.onChange}
            className={styles.input}
            noBorderBottom
            borderBottomLabel
          />
        )}
      </Field>

      <Field name={fields.password.name}>
        {({
          field,
          meta,
        }: FieldProps<{
          password: string;
        }>) => (
          <CustomInput
            isReversed={isReversed}
            variant="password"
            label={t(fields.password.label)}
            key={fields.password.name}
            name={fields.password.name}
            id={fields.password.name}
            value={values.password}
            error={t(meta.error as string) || ''}
            onChange={field.onChange}
            className={styles.input}
            noBorderBottom
            borderBottomLabel
          />
        )}
      </Field>
    </div>
  );
};
