import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Routes } from 'src/components/router/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHook';
import { useSocket } from 'src/hooks/sockets/useSocket';
// import { getFastSettings } from 'src/store/selectors/fastSettings';
import { getSignUp } from 'src/store/selectors/signUp';
import { useSendCredsForVerifyCodeMutation } from 'src/store/services/auth/auth';
// import { useAddCartMutation } from 'src/store/services/profile/profile';
import { setVerificationAlertError, setVerificationInfo } from 'src/store/slices/signUp';

import { IsConfirmVerifyCode, IsConfirmVerifyCodeEvent } from '../../../constants/sign-up.enum';
import { transformMobileNumber } from '../../../helpers/transform-fields';
import { IStepOnePayload } from '../interface/step-one-payload.interface';

interface ISubmitWhatsappProps {
  isOpen: boolean;
  setActiveStep: (val: number) => void;
  error: string;
}

export const useSubmitWhatsapp = ({ isOpen, setActiveStep, error }: ISubmitWhatsappProps) => {
  const { socket } = useSocket(process.env.REACT_APP_NEXT_PUBLIC_WHATSAPP_WEBSOCKET_HOST || '', {
    path: '/public/api/whatsapp/whatsapp-socket/',
  });
  const { generalInfo, addressInfo } = useAppSelector(getSignUp);
  const location = useLocation(); // Заменили router.pathname
  const navigate = useNavigate(); // Заменили router.push()
  const [
    sendCredsForVerifyCode,
    { error: sendCredsForVerifyCodeError, isSuccess: sendCredsForVerifyCodeSuccess },
  ] = useSendCredsForVerifyCodeMutation();

  const dispatch = useAppDispatch();

  const onSendVerificationCode = useCallback(
    async (message: IsConfirmVerifyCode) => {
      dispatch(setVerificationAlertError(message));
      if (message === IsConfirmVerifyCode.Fulfilled) {
        setActiveStep(2);
      }
    },
    [dispatch, setActiveStep],
  );

  const onConnect = useCallback(async () => {
    dispatch(setVerificationAlertError('check email'));
  }, [dispatch]);

  const onConnectError = useCallback(
    async (err: Error) => {
      dispatch(setVerificationAlertError(IsConfirmVerifyCode.ConnectionError));
      console.log('Error:', err.message);
    },
    [dispatch],
  );

  const startListeners = () => {
    if (!socket.connected) {
      socket.connect();
    }
    socket.on(IsConfirmVerifyCodeEvent.SendVerificationCode, onSendVerificationCode);

    socket.on('connect', onConnect);
    socket.on('connect_error', onConnectError);

    return () => {
      socket.off(IsConfirmVerifyCodeEvent.SendVerificationCode, onSendVerificationCode);

      socket.off('connect', onConnect);
      socket.off('connect_error', onConnectError);
    };
  };
  // const { cart, frequency, cleaning, orderAddress } = useSelector(getFastSettings);
  // const [addCart] = useAddCartMutation();
  const whatsappSubmit = async (
    value: Omit<IStepOnePayload, 'successUrl' | 'failUrl' | 'addressInfo' | 'generalInfo'>,
  ) => {
    if (error == IsConfirmVerifyCode.Fulfilled) {
      setActiveStep(2);
      return;
    }

    console.log(process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN);

    // let successUrl = `${process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN}/${location.pathname}/signUp/2`;
    // let failUrl = `${process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN}/${location.pathname}/signUp/1`;

    let successUrl = `${process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN}/signUp/2`;
    let failUrl = `${process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN}/signUp/1`;

    if (location.pathname.includes(Routes.Careers) || location.pathname.includes(Routes.HowToUse)) {
      successUrl = `${process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN}/${location.pathname}/housekeeper-career/signUp/2`;
      failUrl = `${process.env.REACT_APP_NEXT_PUBLIC_CLIENT_DOMAIN}/${location.pathname}/housekeeper-career/signUp/1`;
    }

    const payload: IStepOnePayload = {
      mobileNumber: transformMobileNumber(value.mobileNumber),
      email: value.email,
      successUrl,

      failUrl,
      addressInfo: addressInfo.fields,

      generalInfo: generalInfo.fields,
    };

    console.log('payload1:', payload);
    await sendCredsForVerifyCode(payload);
    console.log('payload2:', payload);
    // await addCart({
    //   phone: payload.mobileNumber,
    //   data: {
    //     cart,
    //     frequency,
    //     cleaning,
    //     orderAddress,
    //   },
    // });
    dispatch(setVerificationInfo(payload));
  };

  useEffect(() => {
    if (sendCredsForVerifyCodeSuccess && error !== IsConfirmVerifyCode.Fulfilled) {
      startListeners();
      dispatch(setVerificationAlertError('check email'));
    }
  }, [dispatch, sendCredsForVerifyCodeSuccess, startListeners]);

  useEffect(() => {
    if (sendCredsForVerifyCodeError) {
      let errorMessage = 'something wrong';
      if ('message' in sendCredsForVerifyCodeError) {
        errorMessage = sendCredsForVerifyCodeError.message!;
      }
      dispatch(setVerificationAlertError(errorMessage));
    }
  }, [sendCredsForVerifyCodeError, dispatch]);

  useEffect(() => {
    if (!isOpen) {
      socket.off(IsConfirmVerifyCodeEvent.SendVerificationCode, onSendVerificationCode);
      socket.off('connect', onConnect);
      socket.off('connect_error', onConnectError);
      socket.disconnect();
    }
  }, [isOpen, onSendVerificationCode, onConnect, onConnectError, socket]);

  return {
    whatsappSubmit,
  };
};
