import React, { CSSProperties, FC, ReactNode } from 'react';
import { clsx as cn } from 'clsx';

import { heights } from '../constants/heights';
import { palettes } from '../constants/palettes';
import { HeightType } from '../interfaces/HeightType';
import { PaletteType } from '../interfaces/PaletteType';

import styles from '../styles.module.scss';

interface IWrapperProps {
  children: ReactNode;
  containerClassName: string;
  error?: string;
  errorClassName?: string;
  label: string;
  labelClassName: string;
  labelTextClassName: string;
  required?: boolean;
  touched?: boolean;
  text?: string | null;
  inputWrapperClassName?: string;
  palette?: PaletteType;
  height?: HeightType;
  wrapperStyle?: CSSProperties;
  lableStyle?: CSSProperties;
}

const Wrapper: FC<IWrapperProps> = ({
  children,
  containerClassName,
  error,
  errorClassName,
  label,
  labelClassName,
  labelTextClassName,
  required,
  touched = false,
  text,
  inputWrapperClassName,
  palette,
  height,
  wrapperStyle,
  lableStyle = {},
}: IWrapperProps) => (
  <div
    className={containerClassName}
    style={wrapperStyle}
  >
    <div
      className={cn(
        styles.inputWrapper,
        inputWrapperClassName,
        palette ? palettes[palette] : '',
        height ? heights[height] : '',
      )}
    >
      <label
        htmlFor={`ph_${styles.input}`}
        className={labelClassName}
      >
        {children}
        {label && (
          <span
            className={labelTextClassName}
            style={lableStyle}
          >
            {label}
            {required && !text && '*'}
          </span>
        )}
      </label>
    </div>
    {touched && error && (
      <span className={cn(styles.error, errorClassName)}>{error}</span>
    )}
  </div>
);

export default Wrapper;
