import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { IAddress } from 'src/components/registration/signUp/forms/address-form/interface/address.interface';
import { IGeneralInfo } from 'src/components/registration/signUp/forms/generalInfo-form/interface/general-info.interface';
import { IPassword } from 'src/components/registration/signUp/forms/password-form/interface/password.interface';
import { IStepOnePayload } from 'src/components/registration/signUp/forms/verification-form/interface/step-one-payload.interface';
import { ISignUpStore } from 'src/components/registration/signUp/interface/sign-up.interface';

const initialState: ISignUpStore = {
  generalInfo: {
    fields: {
      firstName: '',
      lastName: '',
      country: '',
      dateOfBirth: '',
    },
    confirmed: false,
  },
  verificationInfo: {
    fields: { email: '', mobileNumber: '', failUrl: '', successUrl: '' },
    confirmed: false,
    alertError: '',
  },
  addressInfo: {
    fields: {
      address: '',
      houseNumber: '',
      isPrivateHouse: 'No',
      apartmentNumber: '',
      entranceNumber: '',
      entranceCode: '',
      latitude: 0,
      longitude: 0,
      country: '',
      city: '',
      street: '',
    },
    confirmed: false,
  },
  passwordInfo: {
    fields: {
      password: '',
      passwordConfirmation: '',
    },
    confirmed: false,
    alertError: '',
  },
  isSignUp: false,
  lastStep: 0,
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setGeneralInfo(state, { payload }: PayloadAction<IGeneralInfo>) {
      state.generalInfo.fields = payload;
      state.generalInfo.confirmed = true;
    },
    setVerificationInfo(state, { payload }: PayloadAction<IStepOnePayload>) {
      state.verificationInfo.fields = payload;
      state.verificationInfo.confirmed = true;
    },
    setVerificationInfoConfirmed(state, { payload }: PayloadAction<boolean>) {
      state.verificationInfo.confirmed = payload;
    },
    setAddressInfo(state, { payload }: PayloadAction<IAddress>) {
      state.addressInfo.fields = { ...state.addressInfo.fields, ...payload };
      state.addressInfo.confirmed = true;
    },

    setEmailNumber(state, { payload }: PayloadAction<{ email: string; mobileNumber: string }>) {
      console.log(payload);
      state.verificationInfo.fields.email = payload.email;
      state.verificationInfo.fields.mobileNumber = payload.mobileNumber;
    },

    setPasswordInfo(state, { payload }: PayloadAction<IPassword>) {
      state.passwordInfo.fields = payload;
      state.passwordInfo.confirmed = true;
      state.isSignUp = true;
    },
    setPasswordAlertError(state, { payload }: PayloadAction<string>) {
      state.passwordInfo.alertError = payload;
    },
    setVerificationAlertError(state, { payload }: PayloadAction<string>) {
      state.verificationInfo.alertError = payload;
    },
    setClear(state) {
      Object.assign(state, initialState);
    },
    setLastSignUpStep(state, { payload }: PayloadAction<number>) {
      state.lastStep = payload;
    },
  },
});

export const {
  setGeneralInfo,
  setVerificationInfo,
  setAddressInfo,
  setPasswordInfo,
  setVerificationAlertError,
  setPasswordAlertError,
  setClear,
  setVerificationInfoConfirmed,
  setLastSignUpStep,
  setEmailNumber,
} = signUpSlice.actions;

export default signUpSlice.reducer;
