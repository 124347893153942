import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react';
import { clsx as cn } from 'clsx';

import withWrapper from '../helpers/hocs/withWrapper';
import { HeightType } from '../interfaces/HeightType';
import { PaletteType } from '../interfaces/PaletteType';

import styles from '../styles.module.scss';

export interface IDefaultInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  // error this TODO
  children?: ReactNode;
  error?: string;
  errorClassName?: string;
  label: string;
  withInput?: boolean;
  touched?: boolean;
  noHeading?: boolean;
  noBackground?: boolean;
  noBorderBottom?: boolean;
  borderBottomLabel?: boolean;
  fixedLabel?: boolean;
  withIcon?: boolean;
  ref?: ForwardedRef<any>;
  placeholder?: string;
  palette?: PaletteType;
  height?: HeightType;
  isReversed?: boolean;
  wrapperStyle?: CSSProperties;
  lableStyle?: CSSProperties & { textWrap: string };
}

const DefaultInput = forwardRef<HTMLInputElement, IDefaultInputProps>(
  (
    {
      children,
      withInput = true,
      className,
      placeholder,
      palette = 'default',
      height = 'regular',
      ...props
    },
    ref,
  ) => (
    <>
      {withInput && (
        <input
          ref={ref}
          id={`ph_${styles.input}`}
          placeholder={placeholder}
          className={cn(styles.input, className)}
          {...props}
        />
      )}
      {children}
    </>
  ),
);

export default withWrapper(DefaultInput);
