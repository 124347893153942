import { useLocation, useNavigate } from 'react-router-dom';
import { findModalInURL, ModalType } from 'src/utils/helpers/findModalInUrl';

export const useModalNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const switchModal = (newModal?: ModalType, additionalKeys?: { [key: string]: string }) => {
    const currentModal = findModalInURL();
    const searchParams = new URLSearchParams(location.search);

    if (currentModal === newModal) {
      // Закрытие модального окна, убираем параметр 'slug'
      searchParams.delete('slug');
      if (additionalKeys) {
        Object.entries(additionalKeys).forEach(([key, value]) => searchParams.set(key, value));
      }
      navigate({ search: searchParams.toString() }, { replace: true });
      return;
    }

    if (newModal) {
      // Открытие нового модального окна
      searchParams.set('slug', newModal);
      if (additionalKeys) {
        Object.entries(additionalKeys).forEach(([key, value]) => searchParams.set(key, value));
      }
    } else {
      // Если не указано новое модальное окно, удаляем параметр 'slug'
      searchParams.delete('slug');
    }

    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return { switchModal };
};
