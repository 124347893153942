import { generateClassName, IClassNameItem } from 'src/utils/helpers/generateClassName';

import styles from './styles.module.scss';

interface IGetClassNamesSwiper {
  index: number;
  data: number[];
  selectedValue: number;
  paddingLeft: boolean;
  paddingRight: boolean;
  isActive: boolean;
  isPrev: boolean;
  isNext: boolean;
  isVisible: boolean;
}

interface IGetClassNamesSwiperSlider {
  isMonth: boolean;
  isYear: boolean;
  isSmall: boolean;
}

export const getClassNamesSwiper = ({
  index,
  isActive,
  data,
  selectedValue,
  paddingLeft,
  paddingRight,
  isPrev,
  isNext,
  isVisible,
}: IGetClassNamesSwiper) => {
  const selectedIndex = data.indexOf(selectedValue);

  const swiperClassNames: IClassNameItem[] = [
    {
      className: styles.dateItem,
      needDisplay: true,
    },
    {
      className: styles.selected,
      needDisplay: isActive,
    },
    {
      className: styles.neighbors,
      needDisplay: isPrev || isNext,
    },
    {
      className: styles.neighborsTopRotate,
      needDisplay: isPrev,
    },
    {
      className: styles.prevNeighborsTopRotate,
      needDisplay: selectedIndex - 2 === index,
    },
    {
      className: styles.prevNeighborsBottomRotate,
      needDisplay: selectedIndex + 2 === index,
    },
    {
      className: styles.neighborsBottomRotate,
      needDisplay: isNext,
    },
    {
      className: styles.prevNeighbors,
      needDisplay: isVisible && !isPrev && !isNext && !isActive,
    },
    {
      className: styles.paddingLeftNeighbor,
      needDisplay: paddingLeft && (selectedIndex === index - 1 || selectedIndex === index + 1),
    },
    {
      className: styles.paddingLeft,
      needDisplay: paddingLeft,
    },
    {
      className: styles.paddingRightNeighbor,
      needDisplay: paddingRight && (selectedIndex === index - 1 || selectedIndex === index + 1),
    },
    {
      className: styles.paddingRight,
      needDisplay: paddingRight,
    },
  ];

  return generateClassName(swiperClassNames);
};

export const getClassNamesSwiperSlider = ({
  isMonth,
  isYear,
  isSmall,
}: IGetClassNamesSwiperSlider) => {
  const swiperSlideClassNames: IClassNameItem[] = [
    {
      className: styles.months,
      needDisplay: isMonth,
    },
    {
      className: styles.years,
      needDisplay: isYear,
    },
    {
      className: styles.days,
      needDisplay: !(isMonth || isYear),
    },
    {
      className: styles.daysSmall,
      needDisplay: !(isMonth || isYear) && isSmall,
    },
    {
      className: styles.monthsSmall,
      needDisplay: isMonth && isSmall,
    },
    {
      className: styles.yearsSmall,
      needDisplay: isYear && isSmall,
    },
  ];

  return generateClassName(swiperSlideClassNames);
};
