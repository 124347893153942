import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

export const verificationFormSchema = yup.object({
  email: yup
    .string()
    .email('signUpModal.emailValidation')
    .required('signUpModal.emailRequired'),
  mobileNumber: yup
    .string()
    .required('signUpModal.phoneRequired')
    .test('isValidPhoneNumber', 'signUpModal.phoneValidation', (value) =>
      isPossiblePhoneNumber(value || ''),
    ),
});
