import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx as cn } from 'clsx';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';

import Text from 'src/components/lips/text';
import Button from 'src/components/lips/button';
import CustomInput from 'src/components/lips/customInput';
import { PhoneInput } from 'src/components/phoneNumberInput';
import { useAppDispatch } from 'src/hooks/reduxHook';
import { setEmailNumber } from 'src/store/slices/signUp';

import { IStepOnePayload } from '../../interface/step-one-payload.interface';

import { getClassNames } from './config';

import 'react-phone-number-input/style.css';
import styles from './styles.module.scss';

const VerificationField = ({
  alertError,
  isReversed,
}: {
  alertError?: string | undefined;
  isReversed?: boolean;
}) => {
  const { values, setFieldValue, errors, isValid, touched } = useFormikContext<IStepOnePayload>();
  const dispatch = useAppDispatch();
  const { containerClassName, labelTextClassName } = getClassNames({
    touched: touched.mobileNumber,
    error: errors.mobileNumber,
    text: values.mobileNumber,
  });

  useEffect(
    () => () => {
      dispatch(
        setEmailNumber({
          mobileNumber: values.mobileNumber,
          email: values.email,
        }),
      );
    },
    [values.email, values.mobileNumber],
  );

  const { t, i18n } = useTranslation();

  return (
    <div className={styles.containerMain}>
      <Field name="email" id="email">
        {({ meta }: FieldProps<{ email: string }>) => (
          <CustomInput
            variant="default"
            label={t('signUpModal.email')}
            name="email"
            required
            id="email"
            value={values.email}
            error={t(meta.error as string) || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue('email', e.target.value.replace(/\s/g, '').toLowerCase())
            }
          />
        )}
      </Field>
      <Field name="mobileNumber" id="mobileNumber">
        {({ field, meta }: FieldProps<{ mobileNumber: string }>) => (
          <div className={containerClassName}>
            <div className={cn(styles.inputWrapper, isReversed && styles.reversed)}>
              <label htmlFor={`ph_${styles.input}`} className={styles.label}>
                <PhoneInput
                  key="mobileNumber"
                  name="mobileNumber"
                  onBlur={field.onBlur}
                  defaultCountry="il"
                  value={values.mobileNumber}
                  onChange={(value) => setFieldValue(field.name, value)}
                />
                <span className={cn(styles.labelExtra, labelTextClassName)}>
                  {t('signUpModal.phone')}
                </span>
              </label>
            </div>
            {meta.touched && meta.error && (
              <span className={cn(styles.error)}>{t(meta.error as string)}</span>
            )}
          </div>
        )}
      </Field>
      <div className={styles.buttonWrapper}>
        <Button type="submit" palette="primary" disabled={!isValid} className={styles.btnContinue}>
          {t(alertError === 'check email' ? 'signUpModal.resend' : 'signUpModal.next')}
        </Button>
      </div>
    </div>
  );
};

export default VerificationField;
