// ========================== react ==========================
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// ========================== routes ==========================
import AppRoutes from './app.routes';

// ========================== mui =============================
// import { ThemeProvider } from "@mui/material";

// ========================== components ======================
import ErrorBoundaryComp from './components/error-boundary.comp';

// ========================== store ==========================

import store, { persistor } from './store/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// ========================== styles ==========================
import './app.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './i18n';

const queryClient = new QueryClient();

function App() {
  const [isReverse, setIsReverse] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    setIsReverse(i18n.language === 'he');
  }, [i18n.language]);
  return (
    <ErrorBoundaryComp>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <Router>
              <AppRoutes />
            </Router>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </ErrorBoundaryComp>
  );
}

export default App;
