// ========================== react ==========================
import { FC, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ========================== components ==========================

// ========================== mui ==========================
import Box from '@mui/material/Box';

// ========================== store ==========================
import { fetchSignIn } from './store/auth.actions';
import { AuthErrorSelector } from './store/auth.selector';
import LoginForm from '../../../components/login-form.comp';
import { AppDispatch } from '../../../store/store';
import { UserRoles } from '../roles/types/user-roles.enum';
import SignIn from '../../../components/registration/signIn';

// ========================== type ==========================

interface IFormInput {
  email: string;
  password: string;
}

export const handleResponse = (response: any, navigate: (path: string) => void) => {
  if (response.meta.requestStatus !== 'rejected') {
    const user: any = decodeToken(response.payload);
    if (user && user.role_type && user.role_type === UserRoles.user) {
      // window.location.replace("http://localhost:8000");
      navigate('/main');
    } else {
      window.localStorage.setItem('token', response.payload);
      navigate('/main');
    }
  }
};

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const fetchingErrors = useSelector(AuthErrorSelector);
  const [isDraggable, setIsDraggable] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const handleSave = async (data: IFormInput) => {
    const response = await dispatch(fetchSignIn(data));
    handleResponse(response, navigate);
  };

  return (
    // <Box>
    //   <LoginForm handleSave={handleSave} fetchingErrors={fetchingErrors.token} />
    //
    // </Box>
    <SignIn
      createNewAccountHandler={() => console.log('createNewAccountHandler')}
      forgotPasswordHandler={() => console.log('forgotPasswordHandler')}
    />
  );
};
export default LoginPage;
