import { useLocation } from 'react-router-dom';

export const Modals = {
  orders: 'orders',
  blacklist: 'blacklist',
  security: 'security',
  reviews: 'reviews',

  offer: 'offer',
  cart: 'cart',
  open: 'open',
  signIn: 'signIn',
  signUp: 'signUp',
  resetPassword: 'resetPassword',
  forgotPassword: 'forgotPassword',
  notifications: 'notifications',
  logout: 'logout',
  maidRegistration: 'maidRegistration',
  fastSettings: 'fastSettings',
  editProfile: 'editProfile',
  promotion: 'promotion',
  fee: 'fee',
  schedule: 'schedule',
  routes: 'routes',
  pos: 'pos',
  fastSettingsMobile: 'fastSettingsMobile',
  directions: 'directions',
  orderWarning: 'orderWarning',
  addAddress: 'addAddress',
  addAddressMobile: 'addAddressMobile',
  userViewCard: 'userViewCard',
  twoFactorLogin: 'twoFactorLogin',
  securityKeyLogin: 'securityKeyLogin',
  biometricsLogin: 'biometricsLogin',
  paymentRegistration: 'paymentRegistration',
  conditionsConfirmation: 'conditionsConfirmation',
  conditions: 'conditions',
  notificationSettingsMobile: 'notificationSettingsMobile',
};

export type ModalType = keyof typeof Modals;

export const findModalInURL = (): ModalType | '' => {
  // const location = useLocation();
  // const modalParam = new URLSearchParams(location.search).get('slug') ?? '';
  //
  // if (modalParam && Object.values(Modals).includes(modalParam as ModalType)) {
  //   return modalParam as ModalType;
  // }

  return '';
};
