import { IsPrivateHouse } from 'src/components/registration/maidRegistration/constants/IsPrivateHouse';

export const transformRadioValueToBoolean = (value: string): boolean =>
  value === IsPrivateHouse.Yes;
export const transformMobileNumber = (value: string): string => {
  if (/^\+/.test(value)) {
    return value;
  }
  return `+${value}`;
};
