import { generateClassName, IClassNameItem } from 'src/utils/helpers/generateClassName';

import styles from './styles.module.scss';

interface IGetClassNames {
  selected: boolean;
  error: boolean;
  customSelectClass: string;
}

export const getClassNames = ({ selected, error, customSelectClass }: IGetClassNames) => {
  const containerClassNames: IClassNameItem[] = [
    {
      className: styles.container,
      needDisplay: true,
    },
    {
      className: styles.selected,
      needDisplay: selected,
    },
    {
      className: styles.error,
      needDisplay: error,
    },
  ];
  const iconWrapperClassNames: IClassNameItem[] = [
    {
      className: styles.iconWrapper,
      needDisplay: true,
    },
    {
      className: styles.selectedIcon,
      needDisplay: selected,
    },
  ];
  const iconClassNames: IClassNameItem[] = [
    {
      className: styles.icon,
      needDisplay: true,
    },
    {
      className: customSelectClass,
      needDisplay: selected,
    },
    {
      className: styles.color,
      needDisplay: selected,
    },
  ];

  return {
    containerClassNames: generateClassName(containerClassNames),
    iconWrapperClassNames: generateClassName(iconWrapperClassNames),
    iconClassNames: generateClassName(iconClassNames),
  };
};
