import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// Редьюсеры из первого стора
import { apiSlice } from './services/api';
import authenticationSlice from './slices/authentication';
import authModalsSlice from './slices/authModals';
import conditionsSlice from './slices/conditions';
// import feeSlice from './slices/fee';
// import forgotPasswordSlice from './slices/forgotPassword';
import googleMapSlice from './slices/googleMap';
// import maidRegistrationSlice from './slices/maidRegistration';
import paymentSlice from './slices/payment';
// import profileSlice from './slices/profile';
// import securitySlice from './slices/security';
import signInSlice from './slices/signIn';
import signUpSlice from './slices/signUp';
import userSlice from './slices/user';
// import searchSlice from './slices/search';

// Редьюсеры из второго стора
import { authReducer } from 'src/app/store/login/store/auth.slice';
import { usersSlice } from 'src/app/store/users/users.slice';
import pages from 'src/app/store/pages/pages.slice';
import roles from 'src/app/store/roles/roles.slice';
import categories from 'src/app/store/categories/categories.slice';
import tests from 'src/app/store/tests/tests.slice';
import connections from 'src/app/store/connections/connection.slice';
import results from 'src/app/store/results/results.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const createNoopStorage = () => ({
  getItem(_key: any) {
    return Promise.resolve(null);
  },
  setItem(_key: any, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: any) {
    return Promise.resolve();
  },
});
const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

// Конфигурация redux-persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['signUp', 'signIn', 'calendar', 'notification', 'api'],
};

const signUpPersistConfig = {
  key: 'signUp',
  storage,
  blacklist: ['passwordInfo'],
};

// Объединение редьюсеров из обоих стора
const rootReducer = combineReducers({
  // Редьюсеры из первого стора
  signUp: persistReducer(signUpPersistConfig, signUpSlice),
  conditions: conditionsSlice,
  signIn: signInSlice,
  authModals: authModalsSlice,
  payment: paymentSlice,
  // forgotPassword: forgotPasswordSlice,
  // maidRegistration: maidRegistrationSlice,
  // security: securitySlice,
  googleMaps: googleMapSlice,
  user: userSlice,
  authentication: authenticationSlice,
  // fee: feeSlice,
  // profile: profileSlice,
  // search: searchSlice,

  [apiSlice.reducerPath]: apiSlice.reducer,

  // Редьюсеры из второго стора
  auth: authReducer,
  users: usersSlice.reducer,
  categories,
  pages,
  roles,
  tests,
  connections,
  results,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Создание общего стора с добавлением middleware для обоих сторов
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppState = ReturnType<typeof store.getState>;
export default store;
