import React, { FC, useMemo, useState } from 'react';
import {
  CountryData,
  CountryIso2,
  defaultCountries as countriesFromLib,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { clsx as cn } from 'clsx';

import arrowDown from 'src/assets/icons/arrowDown.svg';

import { CountrySelectorDropdown } from './components/CountrySelectorDropdown';

import styles from './styles.module.scss';
import { Simulate } from 'react-dom/test-utils';
import abort = Simulate.abort;

interface PhoneInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  defaultCountry?: CountryIso2 | undefined;
  value?: string;
  onChange: (value: string) => void;
  containerClassName?: string;
  selectorToggleClassName?: string;
  isReversed?: boolean;
  onCountryChange?: (value: string) => void;
}

export const PhoneInput: FC<PhoneInputProps> = ({
  defaultCountry,
  className,
  value,
  readOnly,
  isReversed = false,
  onChange,
  containerClassName,
  selectorToggleClassName,
  onCountryChange,
  ...props
}) => {
  const defaultCountries = useMemo(
    () =>
      countriesFromLib.map((country) => {
        if (country[0] === 'United Kingdom') {
          return ['United Kingdom', 'gb', '44', '.... .......']; // Новые значения для замены
        }
        return country; // Возвращаем неизмененный элемент, если это не 'United Kingdom'
      }),
    [],
  ) as CountryData[];
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { inputValue, country, setCountry, handlePhoneValueChange } = usePhoneInput({
    defaultCountry,
    countries: defaultCountries,
    value: value,
    onChange: ({ phone, country }) => {
      onChange(phone);
      onCountryChange?.(country.dialCode);
    },
  });
  const searchValueLower = searchValue.toLowerCase();

  const matchingCountries = defaultCountries?.filter((country) => {
    const { name, dialCode } = parseCountry(country);
    return (
      name.toLowerCase().startsWith(searchValueLower) ||
      dialCode.toLowerCase().startsWith(searchValueLower)
    );
  });

  const nonMatchingCountries = defaultCountries?.filter((country) => {
    const { name, dialCode } = parseCountry(country);
    return (
      !name.toLowerCase().startsWith(searchValueLower) &&
      !dialCode.toLowerCase().startsWith(searchValueLower)
    );
  });

  const countriesToShow = [...matchingCountries, ...nonMatchingCountries];
  return (
    <div
      className={cn(styles.phoneInputContainer, containerClassName, isReversed && styles.reversed)}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          if (!readOnly) {
            setIsOpenSelect((prev) => !prev);
          }
        }}
        role="button"
        className={cn(styles.selectorToggle, selectorToggleClassName)}
      >
        <FlagImage iso2={country.iso2} />
        <span>
          {/*<ArrowDown className={cn(styles.arrow, isOpenSelect && styles.arrowUp)} />*/}
          <img
            src={arrowDown}
            alt="ArrowDown"
            className={cn(styles.arrow, isOpenSelect && styles.arrowUp)}
          />
        </span>
      </button>
      <input
        inputMode="numeric"
        {...props}
        onChange={handlePhoneValueChange}
        readOnly={readOnly}
        value={inputValue}
        className={cn(styles.input, className)}
      />
      <CountrySelectorDropdown
        setIsOpenSelect={setIsOpenSelect}
        countries={countriesToShow}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        show={isOpenSelect}
        selectedCountry={country.iso2}
        onSelect={(value) => setCountry(value.iso2)}
        onClose={() => setIsOpenSelect((prev) => !prev)}
      />
    </div>
  );
};
