// ========================== mui ==============================
import { Box, CircularProgress } from "@mui/material";

const FallbackComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        // minWidth: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default FallbackComponent;
