import SignUp from '../../../components/registration/signUp';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { AuthErrorSelector } from '../../store/login/store/auth.selector';
import { useState } from 'react';
import { fetchSignIn } from '../../store/login/store/auth.actions';
import { handleResponse } from '../../store/login/login.page';

interface IFormInput {
  email: string;
  password: string;
}

export default function SignUpPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const fetchingErrors = useSelector(AuthErrorSelector);
  const [isDraggable, setIsDraggable] = useState(true);
  const [isOpen, setIsOpen] = useState(true);

  const handleSave = async (data: IFormInput) => {
    const response = await dispatch(fetchSignIn(data));
    handleResponse(response, navigate);
  };
  return (
    <SignUp
      isOpen={isOpen}
      setIsDraggable={setIsDraggable}
      setIsOpen={setIsOpen}
      isReversed={false}
      openSignInHandler={() => console.log('openSignInHandler')}
    />
  );
}
