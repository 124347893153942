// ========================== redux ==========================
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

// ========================== types ==========================
import { UserAssignRoleDto } from "./types/user-assign-role-dto.type";
import { UserUpdateDto } from "./types/user-details-update.type";
import { UserDetailsDto } from "./types/user-details.type";
import { UserDto } from "./types/user-dto.type";
import { UserInfoUpdateDto } from "./types/user-info-update.type";
import { UserTestDto } from "./types/user-test.dto";

// ========================== store ==========================
import $api from "../../../api/api";

// ============ GET USERS ============ //
export const getUsers = createAsyncThunk<UserDto[]>(
  "users/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get("/users");
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET ALL USERS ============ //
export const getAllUsers = createAsyncThunk<UserTestDto[]>(
  "users/getAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<any, AxiosResponse<UserTestDto[], boolean>, any>(
        "/users/details"
        // { params: isActive }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET ADMIN INFO ============ //
export const getAdminInfo = createAsyncThunk(
  "users/getAdminInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<UserDetailsDto> = await $api.get(`/users/profile`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET USER INFO BY ID ============ //
export const getUserInfoById = createAsyncThunk(
  "users/getUserInfo",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<UserDetailsDto> = await $api.get(`/users/${userId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ UPDATE USER INFO ============ //
export const updateUserInfo = createAsyncThunk<UserUpdateDto, UserInfoUpdateDto>(
  "users/updateUserInfo",
  async (userData) => {
    try {
      const response = await $api.put(`/users/${userData.id}`, userData);
      return response.data;
    } catch (error: any) {
      return error.response?.data?.message as string;
    }
  }
);

// ============ DELETE USER ============ //
export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(`/users/${userId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ ASSIGN ROLE ============ //
export const assignRole = createAsyncThunk<UserAssignRoleDto, UserAssignRoleDto>(
  "users/assignRole",
  async (data) => {
    try {
      const newRole = {
        newRole: data.name,
      };
      const response = await $api.post(`/users/assignRole/${data.id}`, newRole);
      return response.data;
    } catch (error: any) {
      return error.response?.data?.message as string;
    }
  }
);
