import { createSlice } from "@reduxjs/toolkit";
import { getAllCategories } from "./categories.action";
import { CategoryDto } from "./types/categories.dto";

interface IInitialState {
  categories: CategoryDto[] | [];
  pending: {
    categories: boolean;
  };
  errors: {
    categories: string | null;
  };
}
const initialState: IInitialState = {
  categories: [],
  pending: {
    categories: false,
  },
  errors: {
    categories: null,
  },
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ============ GET ALL Categories ============ //
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.pending.categories = true;
        state.errors.categories = null;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.pending.categories = false;
        state.categories = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action: any & { payload: any }) => {
        state.pending.categories = false;
        state.errors.categories = action.payload.message;
      });
  },
});

const { actions, reducer } = categoriesSlice;
export default reducer;
