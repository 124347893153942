import { createSlice } from "@reduxjs/toolkit";
import { getAllConnections, getCreateConnection, getDropConnection } from "./connection.actions";
import { ConnectionState } from "./types/connection-state.type";

const initialState: ConnectionState = {
  allConnections: [],
  pending: {
    allConnections: false,
  },
  errors: {
    allConnections: null,
  },
};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ============ GET ALL CONNECTIONS ============ //
    builder
      .addCase(getAllConnections.pending, (state: ConnectionState) => {
        state.pending.allConnections = true;
        state.errors.allConnections = null;
      })
      .addCase(getAllConnections.fulfilled, (state: ConnectionState, action) => {
        state.pending.allConnections = false;
        state.allConnections = action.payload;
      })
      .addCase(
        getAllConnections.rejected,
        (state: ConnectionState, action: any & { payload: any }) => {
          state.pending.allConnections = false;
          state.errors.allConnections = action.payload.message;
        }
      );

    // ============ CREATE CONNECTION ============ //
    builder
      .addCase(getCreateConnection.pending, (state) => {
        state.pending.allConnections = true;
        state.errors.allConnections = null;
      })
      .addCase(getCreateConnection.fulfilled, (state, action) => {
        state.pending.allConnections = false;
        state.allConnections = action.payload;
      })
      .addCase(getCreateConnection.rejected, (state, action: any & { payload: any }) => {
        state.pending.allConnections = false;
        state.errors.allConnections = action.payload.message;
      });

    // ============ DROP CONNECTION ============ //
    builder
      .addCase(getDropConnection.pending, (state) => {
        state.pending.allConnections = true;
        state.errors.allConnections = null;
      })
      .addCase(getDropConnection.fulfilled, (state, action) => {
        state.pending.allConnections = false;
        state.allConnections = action.payload;
      })
      .addCase(getDropConnection.rejected, (state, action: any & { payload: any }) => {
        state.pending.allConnections = false;
        state.errors.allConnections = action.payload.message;
      });
  },
});

const { reducer } = connectionsSlice;
export default reducer;
