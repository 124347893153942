import { FontSizeType } from 'src/components/lips/text/interfaces/FontSizeType';

import styles from '../styles.module.scss';

export const desktopFontSize: Record<FontSizeType, string> = {
  xxs: `${styles.desktop_fontSize_xxs}`,
  xs: `${styles.desktop_fontSize_xs}`,
  sm: `${styles.desktop_fontSize_sm}`,
  base: `${styles.desktop_fontSize_base}`,
  lg: `${styles.desktop_fontSize_lg}`,
  xl: `${styles.desktop_fontSize_xl}`,
  xxl: `${styles.desktop_fontSize_xxl}`,
};
