import * as yup from 'yup';

const validateEmail = (email: string | undefined) =>
  yup.string().email('signInModal.emailValidation').isValidSync(email);

const validatePhone = (phone: string | undefined) =>
  yup
    .string()
    .matches(
      /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/,
      'signInModal.emailValidation',
    )
    .isValidSync(phone);

export const validationSchemaSignIn = yup.object().shape({
  identifier: yup
    .string()
    .required('signInModal.emailRequired')
    .test(
      'emailOrPhone',
      'signInModal.emailValidation',
      (value) => validateEmail(value) || validatePhone(value),
    ),
  password: yup
    .string()
    .required('signInModal.passwordRequired')
    .min(5, 'signInModal.passwordValidation'),
});
