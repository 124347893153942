import { PaletteType } from '../interfaces/PaletteType';

import styles from '../styles.module.scss';

export const palettes: Record<PaletteType, string> = {
  default: `${styles.palettesDefault}`,
  beige: `${styles.palettesBeige}`,
  clearBeige: `${styles.palettesClearBeige}`,
  withoutBorder: `${styles.palettesWithoutBorder}`,
  withoutBorderWithIcon: `${styles.palettesWithoutBorderWithIcon}`,
};
