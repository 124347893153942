import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LANGUAGES } from 'src/constants/languagesSelectionConstants';

export const useDetectLanguage = () => {
  const location = useLocation();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const selectedLng =
      LANGUAGES.find((value) =>
        pathParts.includes(value.value!.toLocaleLowerCase()),
      )?.value?.toLocaleLowerCase() ?? 'en';

    setLanguage(selectedLng);
  }, [location.pathname]);

  return { language };
};
