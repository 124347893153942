import { FC, useEffect, useState } from 'react';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHook';
import { useSocket } from 'src/hooks/sockets/useSocket';
import { getSignUp } from 'src/store/selectors/signUp';
import { useGetSignUpInfoQuery } from 'src/store/services/auth/auth';
// import { useGetCartMutation } from 'src/store/services/profile/profile';

import FormChipsGroup from 'src/components/forms-common-elements/formChipsGroup';

import SignUpChips from './chips/SignUpChips';
import { IsConfirmVerifyCodeEvent } from './constants/sign-up.enum';
import AddressForm from './forms/address-form';
import { IAddress } from './forms/address-form/interface/address.interface';
import GeneralInfoForm from './forms/generalInfo-form';
import { IGeneralInfo } from './forms/generalInfo-form/interface/general-info.interface';
import PasswordForm from './forms/password-form';
import VerificationForm from './forms/verification-form';

import imgRegistration from 'src/assets/images/background-registration-origin.png';

import styles from './styles.module.scss';
import { setClear } from '../../../store/slices/signUp';
import { getSignIn } from '../../../store/selectors/signIn';

export type SignUpFromServer = {
  email: string;
  mobileNumber: string;
  verificationCode: number;
  verified: boolean;
  addressInfo: IAddress;
  generalInfo: IGeneralInfo;
};

interface ISignUpProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  openSignInHandler: () => void;
  setIsDraggable: (value: boolean) => void;
  isReversed?: boolean;
}

const SignUp: FC<ISignUpProps> = ({
  isOpen,
  setIsOpen,
  openSignInHandler,
  setIsDraggable,
  isReversed,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { isLoading } = useGetSignUpInfoQuery(
    { id: query.get('slug')?.split('/')[2] ?? '' },
    { skip: !query.get('slug')?.split('/')[2], refetchOnMountOrArgChange: true },
  );

  // const [getCart] = useGetCartMutation();
  const dispatch = useAppDispatch();

  const onRegistered = () => {
    dispatch(setClear());
  };

  const { socket } = useSocket(process.env.REACT_APP_WHATSAPP_WEBSOCKET_HOST || '', {
    path: '/public/api/whatsapp/whatsapp-socket/',
  });

  const { generalInfo, verificationInfo, addressInfo, lastStep } = useAppSelector(getSignUp);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    socket.on(IsConfirmVerifyCodeEvent.Registered, onRegistered);
    return () => {
      socket.off(IsConfirmVerifyCodeEvent.Registered, onRegistered);
    };
  }, []);

  useEffect(() => {
    console.log('mount');
    if (lastStep && !query.get('slug')?.split('/')[1]) {
      navigate(
        {
          pathname: location.pathname,
          search: `?slug=${query.get('slug')?.split('/')[0]}/${lastStep}`,
        },
        { replace: true }, // Заменяем текущий URL
      );
    }
  }, [lastStep]);

  // useEffect(() => {
  //   if (verificationInfo?.fields?.mobileNumber) {
  //     getCart({ phone: verificationInfo?.fields?.mobileNumber ?? '' });
  //   }
  // }, [verificationInfo?.fields?.mobileNumber]);

  const activeStep = Number(query.get('slug')?.split('/')[1] ?? 0);

  const setActiveStep = (number: number) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?slug=${query.get('slug')?.split('/')[0]}/${number}`,
      },
      { replace: true },
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.formRegistration}>
        <img src={imgRegistration} alt="imgRegistration" className={styles.imgRegistration} />
        <div className={styles.containerChipsGroup}>
          <h1>Account creation</h1>
          <FormChipsGroup
            chips={<SignUpChips />}
            selectedIndex={activeStep}
            setSelectedIndex={setActiveStep}
          >
            <div className={styles.mainContainer}>
              {activeStep === 0 && (
                <GeneralInfoForm
                  setActiveStep={setActiveStep}
                  initialValues={generalInfo.fields}
                  setIsDraggable={setIsDraggable}
                  isReversed={isReversed}
                  openSignInHandler={openSignInHandler}
                />
              )}
              {activeStep === 1 && (
                <VerificationForm
                  isReversed={isReversed}
                  setActiveStep={setActiveStep}
                  isOpen={isOpen}
                  initialValues={verificationInfo.fields}
                />
              )}
              {activeStep === 2 && !isLoading && (
                <>
                  {query.get('slug')?.split('/')[2] && verificationInfo.fields.mobileNumber && (
                    <PasswordForm isReversed={isReversed} setIsOpen={setIsOpen} />
                  )}
                  {!query.get('slug')?.split('/')[2] && (
                    <PasswordForm isReversed={isReversed} setIsOpen={setIsOpen} />
                  )}
                </>
              )}
              {/*{activeStep === 3 && <PasswordForm isReversed={isReversed} setIsOpen={setIsOpen} />}*/}
            </div>
          </FormChipsGroup>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
