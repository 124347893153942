import React, { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';

import CustomInput from 'src/components/lips/customInput';
import DatePickerInput from 'src/components/lips/datePickerInput';
import { useAppDispatch } from 'src/hooks/reduxHook';
import { useDetectLanguage } from 'src/hooks/useDetectLanguage';
import { setGeneralInfo } from 'src/store/slices/signUp';

import { IGeneralInfo } from '../interface/general-info.interface';

import styles from './styles.module.scss';
import RadioGroupInput from 'src/components/lips/radioGroupInput';
import Button from '../../../../../lips/button';
import Text from '../../../../../lips/text';
import { StyleLabelPalette } from 'src/constants/styleLabels';
import { useNavigate } from 'react-router-dom';

interface IGeneralInfoFieldProps {
  setIsDraggable: (value: boolean) => void;
  isReversed?: boolean;
  openSignInHandler?: () => void;
}

const GeneralInfoField: FC<IGeneralInfoFieldProps> = ({ setIsDraggable, isReversed }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { values, setFieldValue, isValid } = useFormikContext<IGeneralInfo>();
  const { t } = useTranslation();
  const { language } = useDetectLanguage();
  useEffect(
    () => () => {
      console.log(values);
      if (isValid) {
        dispatch(setGeneralInfo(values));
      }
    },
    [values, isValid],
  );

  const handleClickLogin = () => {
    navigate('/signIn');
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerNames}>
        <Field name="firstName">
          {({
            meta,
          }: FieldProps<{
            firstName: string;
          }>) => (
            <CustomInput
              variant="default"
              label={t('signUpModal.firstName')}
              name="firstName"
              required
              id="firstName"
              value={values.firstName}
              error={t(meta.error as string) || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue('firstName', e.target.value.replace(/\s/g, ''))
              }
            />
          )}
        </Field>
        <Field name="lastName">
          {({
            meta,
          }: FieldProps<{
            lastName: string;
          }>) => (
            <CustomInput
              variant="default"
              label={t('signUpModal.lastName')}
              name="lastName"
              id="lastName"
              value={values.lastName}
              error={t(meta.error as string) || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue('lastName', e.target.value.replace(/\s/g, ''))
              }
            />
          )}
        </Field>
      </div>

      <Field name="dateOfBirth">
        {() => (
          <DatePickerInput
            isDatePickerOpen={false}
            setIsDatePickerOpen={() => {
              console.log(1);
            }}
            isReversed={isReversed}
            name="dateOfBirth"
            caption={t('signUpModal.dateOfBirth') || ''}
            id="dateOfBirth"
            value={values.dateOfBirth}
            language={language}
            onChangeForm={setFieldValue}
            setIsDraggable={setIsDraggable}
            calendarWrapperClassName={clsx(isReversed && styles.calendarWrapperReversed)}
          />
        )}
      </Field>
      <Field name="country">
        {({
          meta,
        }: FieldProps<{
          lastName: string;
        }>) => (
          <CustomInput
            variant="default"
            label={t('signUpModal.country')}
            name="country"
            id="country"
            value={values.country}
            error={t(meta.error as string) || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue('country', e.target.value.replace(/\s/g, ''))
            }
          />
        )}
      </Field>
      <Button type="submit" disabled={!isValid} className={styles.btnNext}>
        {t('signUpModal.next')}
      </Button>
      <div className={styles.buttonWrapper}>
        <p>Already have an account?</p>
        <Button className={styles.signInButton} palette="secondary" onClick={handleClickLogin}>
          {t('signUpModal.signInText')}
        </Button>
      </div>
    </div>
  );
};

export default GeneralInfoField;
