import React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx as cn } from 'clsx';

import Button from 'src/components/lips/button';

import styles from './styles.module.scss';

interface IDatePickerButtonsProps {
  onDoneClick: VoidFunction;
  onCloseClick: VoidFunction;
}

const DatePickerButtons: FC<IDatePickerButtonsProps> = ({ onDoneClick, onCloseClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.buttonWrapper}>
      <Button
        type="button"
        palette="secondary"
        radius="large"
        className={styles.button}
        onClick={onCloseClick}
      >
        {t('absencePeriodModal.cancel')}
      </Button>
      <Button
        type="button"
        palette="secondary"
        radius="large"
        className={cn(styles.button, styles.textColor)}
        onClick={onDoneClick}
      >
        {t('absencePeriodModal.done')}
      </Button>
    </div>
  );
};

export default DatePickerButtons;
