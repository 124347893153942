import * as yup from 'yup';

export const generalInfoFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'signUpModal.firstNameMinLength')
    .max(50, 'signUpModal.firstNameMaxLength')
    .matches(/^[A-Za-zа-яА-ЯЁёא-ת]+$/u, 'signUpModal.wrongFormat')
    .required('signUpModal.firstNameValidation'),
  lastName: yup
    .string()
    .matches(/^[A-Za-zа-яА-ЯЁёא-ת]+$/u, 'signUpModal.wrongFormat')
    .min(2, 'signUpModal.lastNameMinLength')
    .max(50, 'signUpModal.lastNameMaxLength'),
});
