import React, { InputHTMLAttributes } from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { clsx as cn } from 'clsx';

import arrow from 'src/assets/icons/arrowNotFilled.svg';
import Input from 'src/components/lips/input';

import { getClassNames } from './config';

import styles from './styles.module.scss';

export interface IEditableInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  touched?: boolean;
  error?: string;
  onBlur?: (val: any) => void;
  onChange?: (val: any) => void;
  id?: string;
  isRequired?: boolean;
}

const EditableInput: FC<IEditableInputProps> = ({
  touched,
  error,

  ...props
}) => {
  const [editMode, setEditMode] = useState(false);

  const shownError = error && touched;

  const inputRef = useRef<HTMLInputElement>(null);

  const { containerClassName, arrowWrapperClassName, imageClassName, errorClassName } =
    getClassNames(editMode);

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus();
    }
  }, [editMode]);

  return (
    <div className={containerClassName} onClick={() => setEditMode((prevState) => !prevState)}>
      <div className={arrowWrapperClassName}>
        <img src={arrow as unknown as string} alt="arrow" className={imageClassName} />
      </div>
      <Input
        touched={touched}
        error={error}
        disabled={!editMode}
        onClick={(e) => e.stopPropagation()}
        inputRef={inputRef}
        editMode={editMode}
        className={cn(styles.inputWrapper)}
        inputClassName={styles.input}
        {...props}
      />
      {shownError && <span className={errorClassName}>{error}</span>}
    </div>
  );
};

export default EditableInput;
