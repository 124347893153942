import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface IAuthModals {
  signIn: boolean;
  signUp: boolean;
  resetPassword: boolean;
  forgotPassword: boolean;
}

const initialState: IAuthModals = {
  signIn: false,
  signUp: false,
  resetPassword: false,
  forgotPassword: false,
};

const authModalsSlice = createSlice({
  name: 'authModals',
  initialState,
  reducers: {
    setSignIn(state, { payload }: PayloadAction<boolean>) {
      state.signIn = payload;
    },
    setSignUp(state, { payload }: PayloadAction<boolean>) {
      state.signUp = payload;
      state.signIn = false;
    },
    setResetPassword(state, { payload }: PayloadAction<boolean>) {
      state.resetPassword = payload;
      state.signIn = false;
    },
    setForgotPassword(state, { payload }: PayloadAction<boolean>) {
      state.forgotPassword = payload;
    },
    setForgotReset(
      state,
      {
        payload,
      }: PayloadAction<{ resetPassword: boolean; forgotPassword: boolean }>,
    ) {
      state.forgotPassword = payload.forgotPassword;
      state.resetPassword = payload.resetPassword;
    },
    setSignInReset(
      state,
      { payload }: PayloadAction<{ resetPassword: boolean; signIn: boolean }>,
    ) {
      state.signIn = payload.signIn;
      state.resetPassword = payload.resetPassword;
    },

    setClearAuthModal(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setForgotReset,
  setForgotPassword,
  setResetPassword,
  setSignUp,
  setSignIn,
  setSignInReset,
  setClearAuthModal,
} = authModalsSlice.actions;

export default authModalsSlice.reducer;
