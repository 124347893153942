export enum Routes {
  Search = '/[[...slug]]',
  CustomerSearch = '/search/[[...slug]]',
  Map = '/map/[[...slug]]',
  Customer = '/customer/[[...slug]]',
  Maid = '/maid/[[...slug]]',
  Conditions = '/conditions-of-use/[[...slug]]',
  AboutUs = '/about-us/[[...slug]]',
  Careers = '/housekeeper-career/[[...slug]]',
  HowToUse = '/how-to-use/[[...slug]]',
  BusyHousekeepers = '/busy-housekeepers/[[...slug]]',
  Housekeeper = '/housekeeper',
  Main = '/main',
}
