import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { setRole } from '../slices/authentication';
import { removeUser } from '../slices/user';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_AUTH_HOST,
  headers: { Accept: 'application/json' },
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    api.dispatch(removeUser());
    // api.dispatch(resetMaidRegistration());
    api.dispatch(setRole('Guest'));

    // if (
    //   Router.pathname != Routes.Search &&
    //   Router.pathname != Routes.Map &&
    //   Router.pathname != Routes.Careers &&
    //   Router.pathname != Routes.AboutUs &&
    //   Router.pathname != Routes.HowToUse
    // ) {
    //   Router.replace(Routes.Search);
    //   if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker
    //       .getRegistration('/')
    //       .then(function (registration) {
    //         if (registration) registration.unregister();
    //       });
    //   }
    // }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Notifications', 'Orders', 'Security-keys', 'Current-profile'],
  endpoints: () => ({}),
});
