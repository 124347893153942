import { IsConfirmVerifyCode } from 'src/components/registration/forgotPassword/forms/confirm-form/constants/IsConfirmVerifyCode.enum';

interface IErrorMessage {
  main: string;
  isConfirmVerifyCode: IsConfirmVerifyCode;
}

const useErrorMessageVerification = (error: string): [IErrorMessage] => {
  const errorMessage: IErrorMessage = {
    main: '',
    isConfirmVerifyCode: IsConfirmVerifyCode.Default,
  };

  switch (error) {
    case IsConfirmVerifyCode.ConnectionError:
      errorMessage.main = 'signUpModal.errorConnectedWS';
      errorMessage.isConfirmVerifyCode = IsConfirmVerifyCode.ConnectionError;
      break;
    case IsConfirmVerifyCode.Fulfilled:
      errorMessage.main = 'signUpModal.codeSuccessfully';
      errorMessage.isConfirmVerifyCode = IsConfirmVerifyCode.Fulfilled;
      break;
    case IsConfirmVerifyCode.Reject:
      errorMessage.main = 'signUpModal.codeWrong';
      errorMessage.isConfirmVerifyCode = IsConfirmVerifyCode.Reject;
      break;
    case 'check email':
      errorMessage.main = 'signUpModal.checkEmail';
      errorMessage.isConfirmVerifyCode = IsConfirmVerifyCode.Fulfilled;
      break;
    case IsConfirmVerifyCode.Pending:
      errorMessage.main = 'signUpModal.loading';
      errorMessage.isConfirmVerifyCode = IsConfirmVerifyCode.Fulfilled;
      break;

    default:
      errorMessage.main = error;
      errorMessage.isConfirmVerifyCode = IsConfirmVerifyCode.Reject;
      break;
  }

  return [errorMessage];
};

export default useErrorMessageVerification;
