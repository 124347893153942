import React, { FC, useEffect, useState } from 'react';
import { clsx as cn } from 'clsx';
import SwiperCore, { Manipulation, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { datePickerStartYear } from '../constants/datePickerStartYear';
import { SwiperPickerSlide } from '../swiperPickerSlide';

import { onSlideChange } from './helpers/onSlideChange';
import { getClassNamesSwiperSlider } from './config';

import 'swiper/swiper.min.css';
import 'swiper/css';
import styles from './styles.module.scss';

SwiperCore.use([Navigation]);

interface ISwiperPickerProps {
  slides: Array<string | number>;
  currentValue: number;
  swiperRef: React.MutableRefObject<SwiperCore | null>;
  isSmall: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  isMonth?: boolean;
  isYear?: boolean;
  monthIndexes?: Array<number>;
  handleChange: (value: number) => void;
  setIsDraggable: (value: boolean) => void;
}

const SwiperPicker: FC<ISwiperPickerProps> = ({
  slides,
  currentValue,
  swiperRef,
  isSmall,
  paddingLeft = false,
  paddingRight = false,
  isMonth = false,
  isYear = false,
  monthIndexes = [],
  handleChange,
  setIsDraggable,
}) => {
  const [selectedValue, setSelectedValue] = useState<number>(currentValue);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.slideToLoop(selectedValue - 1, 0, false);

      if (isYear && swiperRef.current && swiperRef.current.slides) {
        const yearIndex = selectedValue - datePickerStartYear;
        swiperRef.current.slideToLoop(yearIndex, 0, false);
      }
    }
  }, []);

  return (
    <div
      className={cn(
        styles.datePickerSection,
        isMonth && styles.monthSection,
        !isMonth && !isYear && styles.daySection,
        isSmall && styles.isPeriod,
      )}
      onTouchStart={() => setIsDraggable(false)}
      onTouchEnd={() => setIsDraggable(true)}
    >
      <Swiper
        watchSlidesProgress
        touchMoveStopPropagation
        touchStartPreventDefault
        touchStartForcePreventDefault
        direction="vertical"
        slidesPerView={5}
        centeredSlides
        loop
        mousewheel
        modules={[Mousewheel, Manipulation]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => {
          const currentValue = onSlideChange({ swiperRef, isYear, swiper });

          setSelectedValue(currentValue);
          handleChange(currentValue);
        }}
        onTouchStart={() => setIsDraggable(false)}
        onTouchEnd={() => setIsDraggable(true)}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            className={getClassNamesSwiperSlider({ isMonth, isYear, isSmall })}
            key={index}
          >
            {({ isActive, isPrev, isNext, isVisible }) => (
              <SwiperPickerSlide
                isActive={isActive}
                isPrev={isPrev}
                isNext={isNext}
                isVisible={isVisible}
                index={index}
                isMonth={isMonth}
                isSmall={isSmall}
                isYear={isYear}
                handleChange={handleChange}
                slide={slide}
                slides={slides}
                selectedValue={selectedValue}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                monthIndexes={monthIndexes}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperPicker;
