import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

interface ICardProps {
  children: ReactNode;
  shadow?: boolean;
  className?: string;
  noBorder?: boolean;
}

const Card: React.FC<ICardProps> = ({ children, shadow = false, className, noBorder }) => {
  const cx = classNames.bind(styles);
  const cardClassnames = cx('card', 'borderMain', className, {
    borderShadow: shadow,
    noBorder,
  });

  return <div className={cardClassnames}>{children}</div>;
};

export default Card;
