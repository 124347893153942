import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { apiClient } from 'src/lib/apiClient/apiClient';
// import Router from 'next/router';

// import { IsConfirmVerifyCode } from 'src/components/busyHousekeepers/constants/sign-up.enum';
// import { VerifyForgotPasswordResponse } from 'src/components/registration/forgotPassword/constants/forgot-password.enum';
// import type { InitFormikValuesResetPassword } from 'src/components/registration/resetPassword/form-model/reset-password.interfaces';
import type { InitFormikValuesSignIn } from 'src/components/registration/signIn/form-model/sign-in.interfaces';
import { SignUpFromServer } from 'src/components/registration/signUp';
import { IStepOnePayload } from 'src/components/registration/signUp/forms/verification-form/interface/step-one-payload.interface';
import { IFullFormikValues } from 'src/components/registration/signUp/interface/sign-up.interface';
import { setSignInReset } from 'src/store/slices/authModals';
// import { setClear, setEmail, setPassword } from 'src/store/slices/forgotPassword';
import { setLoginError } from 'src/store/slices/signIn';
import { setIdentifier, setPassword as setPasswordSignIn } from 'src/store/slices/signIn';
import {
  setAddressInfo,
  setClear as setClearSignUp,
  setGeneralInfo,
  setVerificationAlertError,
  setVerificationInfo,
  setVerificationInfoConfirmed,
} from 'src/store/slices/signUp';
import { setUser } from 'src/store/slices/user';
import { AppState } from 'src/store/store';

import { apiSlice } from '../api';
import { PathRequest } from '../path-request.enum';
import { UserWithProfiles } from '../profile/dto/get-user-profile.response';

import { ChangePasswordDto } from './dto/change-password';
import { GetEmailVerifyCodeResponse } from './dto/get-email-verify-code.response';
import type { LoginResponse } from './dto/login.response';
import type { RegistrationResponse } from './dto/registration.response';

export const registrationApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    registration: build.mutation<
      RegistrationResponse | string | unknown,
      Omit<IFullFormikValues, 'passwordConfirmation'>
    >({
      async queryFn(payload: Omit<IFullFormikValues, 'passwordConfirmation'>) {
        const response = (await apiClient.post<RegistrationResponse>(
          PathRequest.Registration,
          // 'http://localhost:4000/api/auth/registration',
          payload,
        )) as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;

        if (typeof response === 'object' && 'data' in response!) {
          return { data: response };
        }
        return { data: response };
      },
    }),
    login: build.mutation<LoginResponse | string | unknown, InitFormikValuesSignIn>({
      async queryFn(payload: InitFormikValuesSignIn) {
        const response = (await apiClient.post<LoginResponse>(
          // 'http://localhost:4000/api/auth/login',
          PathRequest.Login,
          payload,
        )) as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;

        if (response.error) {
          return { error: response.error };
        }
        return { data: response };
      },
      invalidatesTags: ['Notifications'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;

          if (typeof res.data === 'object' && res.data !== null && 'message' in res.data) {
            if (res.data.message === 'Need one-time password') {
              dispatch(setIdentifier(arg.identifier));
              dispatch(setPasswordSignIn(arg.password));
              // Router.push({ query: { ...Router.query, slug: ['twoFactorLogin'] } }, undefined, {
              //   shallow: true,
              // });
            }

            if (res.data.message === 'Need security key') {
              dispatch(setIdentifier(arg.identifier));
              dispatch(setPasswordSignIn(arg.password));
              // Router.push({ query: { ...Router.query, slug: ['securityKeyLogin'] } }, undefined, {
              //   shallow: true,
              // });
            }
            if (res.data.message === 'Need biometrics') {
              dispatch(setIdentifier(arg.identifier));
              dispatch(setPasswordSignIn(arg.password));
              // Router.push({ query: { ...Router.query, slug: ['biometricsLogin'] } }, undefined, {
              //   shallow: true,
              // });
            }
            if (res.data.message === '') {
              dispatch(setIdentifier(arg.identifier));
              dispatch(setPasswordSignIn(arg.password));
              // Router.push({ query: { ...Router.query, slug: ['twoFactorLogin'] } }, undefined, {
              //   shallow: true,
              // });
            }
          }
        } catch (error) {
          console.log(error);
          if (error) {
            // let errorMessage = VerifyForgotPasswordResponse.RejectUnknown;
            // if (typeof error === 'object' && 'message' in error) {
            //   errorMessage = error.message! as VerifyForgotPasswordResponse;
            // }
            // dispatch(setLoginError(errorMessage));
          }
        }
      },
    }),

    changePassword: build.mutation<any | string | unknown, ChangePasswordDto>({
      async queryFn(payload: ChangePasswordDto) {
        const response = (await apiClient.post<any>(
          PathRequest.ChangePassword,
          payload,
        )) as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;

        if (response.error) {
          return { error: response.error };
        }
        return { data: response.data };
      },
      invalidatesTags: ['Notifications'],
    }),
    confirmPolicy: build.mutation<void, void>({
      query: () => ({
        url: PathRequest.ConfirmConditions,
        method: 'POST',
      }),
    }),
    // resetPassword: build.mutation<
    //   void | string | unknown,
    //   InitFormikValuesResetPassword & { email: string; code: number }
    // >({
    //   async queryFn(payload: InitFormikValuesResetPassword) {
    //     const response = await apiClient.post<void>(PathRequest.ResetPassword, payload);
    //     return { data: response };
    //   },
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const res = await queryFulfilled;
    //       if ('data' in res && typeof res.data === 'object' && 'message' in res.data!) {
    //         dispatch(setClear());
    //         dispatch(setSignInReset({ resetPassword: false, signIn: true }));
    //       }
    //       if (
    //         'data' in res &&
    //         typeof res.data === 'object' &&
    //         'error' in res.data! &&
    //         typeof res.data.error === 'object' &&
    //         'message' in res.data.error!
    //       ) {
    //         dispatch(
    //           setPassword({
    //             password: arg.password,
    //             passwordConfirmation: arg.passwordConfirmation,
    //             alertError: res.data.error.message as string,
    //             confirmed: false,
    //           }),
    //         );
    //       }
    //     } catch (error) {
    //       console.log(error);
    //       if (error) {
    //         // let errorMessage = VerifyForgotPasswordResponse.RejectUnknown;
    //         // if (typeof error === 'object' && 'message' in error) {
    //         //   errorMessage = error.message! as VerifyForgotPasswordResponse;
    //         // }
    //         // dispatch(
    //         //   setPassword({
    //         //     password: arg.password,
    //         //     passwordConfirmation: arg.passwordConfirmation,
    //         //     alertError: errorMessage,
    //         //     confirmed: false,
    //         //   }),
    //         // );
    //       }
    //     }
    //   },
    // }),

    checkUserByEmailPhone: build.query<void | string | unknown, void>({
      async queryFn(_, { getState }) {
        const state = getState() as AppState;
        const query = `${PathRequest.CheckUserByEmailPhone}?email=${state.signUp.verificationInfo.fields.email}&phone=${state.signUp.verificationInfo.fields.mobileNumber}`;
        const response = await apiClient.get<string>(query);
        return { data: response };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;

          if ((res?.data as any)?.exists) {
            dispatch(setClearSignUp());

            // if (Router.query?.slug?.[0] == 'signUp') {
            //   Router.push(
            //     {
            //       pathname: Router.pathname,
            //       query: {
            //         ...Router.query,
            //         slug: [Router?.query?.slug?.[0] as string, '0'] as any,
            //       },
            //     },
            //     undefined,
            //     { shallow: true },
            //   );
            // }
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),

    forgotPasswordFetch: build.mutation<void | string | unknown, { email: string }>({
      async queryFn(payload: { email: string }) {
        const response = (await apiClient.post<string>(
          PathRequest.ForgotPassword,
          payload,
        )) as QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>;
        return { data: response };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          // if ('data' in res && typeof res.data === 'object' && 'message' in res.data!) {
          //   dispatch(
          //     setEmail({
          //       email: arg.email,
          //       alertError: res.data.message as string,
          //       confirmed: true,
          //     }),
          //   );
          // }
          // if (
          //   'data' in res &&
          //   typeof res.data === 'object' &&
          //   'error' in res.data! &&
          //   typeof res.data.error === 'object' &&
          //   'message' in res.data.error!
          // ) {
          //   dispatch(
          //     setEmail({
          //       email: arg.email,
          //       alertError: res.data.error.message as string,
          //       confirmed: false,
          //     }),
          //   );
          // }
        } catch (error) {
          console.log(error);
          if (error) {
            // let errorMessage = VerifyForgotPasswordResponse.RejectUnknown;
            // if (typeof error === 'object' && 'message' in error) {
            //   errorMessage = error.message! as VerifyForgotPasswordResponse;
            // }
            // dispatch(
            //   setEmail({
            //     email: arg.email,
            //     alertError: errorMessage,
            //     confirmed: false,
            //   }),
            // );
          }
        }
      },
    }),
    sendCredsForVerifyCode: build.mutation<void | string | unknown, IStepOnePayload>({
      async queryFn(payload: IStepOnePayload) {
        const apiUrl = process.env.REACT_APP_NEXT_PUBLIC_WHATSAPP_WEBSOCKET_HOST;
        console.log('payload', payload);
        console.log('apiUrl:', apiUrl);
        const response = await apiClient.post<void>(
          PathRequest.GetVerifyCode,
          payload,
          '',
          process.env.REACT_APP_NEXT_PUBLIC_WHATSAPP_WEBSOCKET_HOST,
        );
        return { data: response };
      },
    }),
    getSignUpInfo: build.query<void | string | unknown, { id: string }>({
      async queryFn({ id }: { id: string }) {
        const response = await apiClient.get<void>(
          `${PathRequest.getSignUpInfo}/${id}`,
          '',
          process.env.REACT_APP_NEXT_PUBLIC_WHATSAPP_WEBSOCKET_HOST,
        );
        return { data: response };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const res = await queryFulfilled;
          const info = res.data as SignUpFromServer;

          if (info.mobileNumber && info.email) {
            if (info.generalInfo) {
              dispatch(setGeneralInfo(info.generalInfo));
            }
            dispatch(
              setVerificationInfo({
                mobileNumber: info.mobileNumber,
                successUrl: '',
                failUrl: '',
                email: info.email,
              }),
            );
            dispatch(setVerificationInfoConfirmed(info.verified));
            if (info.addressInfo) {
              dispatch(setAddressInfo(info.addressInfo));
            }
            // dispatch(
            //   setVerificationAlertError(
            //     info.verified ? IsConfirmVerifyCode.Fulfilled : IsConfirmVerifyCode.Reject,
            //   ),
            // );
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getEmailVerifyCode: build.mutation<
      GetEmailVerifyCodeResponse | string | unknown,
      { email: string }
    >({
      async queryFn(payload: { email: string }) {
        const response = await apiClient.post<GetEmailVerifyCodeResponse>(
          PathRequest.GetEmailVerifyCode,
          payload,
        );
        return { data: response };
      },
    }),
    updateUser: build.mutation<
      UserWithProfiles | string | unknown,
      Omit<UserWithProfiles, 'profiles'>
    >({
      async queryFn(payload: Omit<UserWithProfiles, 'profiles'>) {
        const response = await apiClient.put<UserWithProfiles>(PathRequest.Update, payload);
        return { data: response };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
        const res = await queryFulfilled;
        const user = res.data;
        dispatch(
          setUser({
            ...(getState() as any).user,
            ...(user as UserWithProfiles),
          } as UserWithProfiles),
        );
      },
    }),
    loginUserAdmin: build.mutation<void | string | unknown, { userId: string; profileId: string }>({
      async queryFn(dto) {
        const response = await apiClient.post<void>(PathRequest.AdminLoginUser, dto);
        return { data: response };
      },
    }),
  }),
});

export const {
  useRegistrationMutation,
  useLoginMutation,
  // useResetPasswordMutation,
  useForgotPasswordFetchMutation,
  useSendCredsForVerifyCodeMutation,
  useGetEmailVerifyCodeMutation,
  useUpdateUserMutation,
  useLoginUserAdminMutation,
  useGetSignUpInfoQuery,
  useChangePasswordMutation,
  useCheckUserByEmailPhoneQuery,
  useConfirmPolicyMutation,
} = registrationApi;
