import React from 'react';
import { useTranslation } from 'react-i18next';

import Camera from 'src/assets/icons/camera.svg';

// import Location from '@/assets/icons/location.svg?inline';
// import Phone from '@/assets/icons/phone.svg?inline';
// import Protection from '/assets/icons/protection.svg?inline';

import { useAppSelector } from 'src/hooks/reduxHook';
import { getSignUp } from 'src/store/selectors/signUp';
import styles from '../styles.module.scss';
import FormChip from '../../../forms-common-elements/formChip';

const SignUpChips = () => {
  const { isSignUp, generalInfo, verificationInfo, addressInfo, passwordInfo } =
    useAppSelector(getSignUp);

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.cheepContainer}>
        <FormChip
          customSelectClass="color"
          icon={Camera}
          text={t('signUpModal.generalInfoChip')}
          error={isSignUp && !generalInfo.confirmed}
        />
      </div>
      <div className={styles.separator} />
      <div className={styles.cheepContainer}>
        <FormChip
          customSelectClass="colorStroke"
          // icon={Phone}
          icon={Camera}
          text={t('signUpModal.verificationChip')}
          error={isSignUp && !verificationInfo.confirmed}
        />
      </div>
      <div className={styles.separator} />
      <div className={styles.cheepContainer}>
        <FormChip
          customSelectClass="colorStroke"
          // icon={Location}
          icon={Camera}
          text={t('signUpModal.addressChip')}
          error={isSignUp && !addressInfo.confirmed}
        />
      </div>
      <div className={styles.separator} />
      <div className={styles.cheepContainer}>
        <FormChip
          customSelectClass="color"
          // icon={Protection}
          icon={Camera}
          text={t('signUpModal.passwordChip')}
          error={isSignUp && !passwordInfo.confirmed}
        />
      </div>
    </>
  );
};

export default SignUpChips;
