import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  identifier: '',
  password: '',
  isOpenTwoFactor: false,
  error: '',
  isOpenSecurityKey: false,
};

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setIdentifier(state, action: PayloadAction<string>) {
      state.identifier = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setLoginError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setClearLoginError(state) {
      state.error = '';
    },
    setIsOpenTwoFactor(state, action: PayloadAction<boolean>) {
      state.isOpenTwoFactor = action.payload;
    },
    setIsOpenSecurityKey(state, action: PayloadAction<boolean>) {
      state.isOpenSecurityKey = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const {
  setIdentifier,
  setPassword,
  setIsOpenTwoFactor,
  setIsOpenSecurityKey,
  setError,
} = signInSlice.actions;
export const { setLoginError, setClearLoginError } = signInSlice.actions;

export default signInSlice.reducer;
