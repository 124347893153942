import { createSlice } from "@reduxjs/toolkit";
import { getAllResults, getAllUserResults, getCurrentTestResult } from "./results.actions";
import { ResultState } from "./types/result-state";

const initialState: ResultState = {
  allResults: [],
  userResults: [],
  userResult: null,
  pending: {
    allResults: false,
    userResults: false,
    userResult: false,
  },
  errors: {
    allResults: null,
    userResults: null,
    userResult: null,
  },
};

export const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ============ GET ALL RESULTS ============ //
    builder
      .addCase(getAllResults.pending, (state) => {
        state.pending.allResults = true;
        state.errors.allResults = null;
      })
      .addCase(getAllResults.fulfilled, (state, action) => {
        state.pending.allResults = false;
        state.allResults = action.payload;
      })
      .addCase(getAllResults.rejected, (state, action: any & { payload: any }) => {
        state.pending.allResults = false;
        state.errors.allResults = action.payload.message;
      });

    // ============ GET USER RESULTS BY USER ID ============ //

    builder
      .addCase(getAllUserResults.pending, (state) => {
        state.pending.userResults = true;
        state.errors.userResults = null;
      })
      .addCase(getAllUserResults.fulfilled, (state, action) => {
        state.pending.userResults = false;
        state.userResults = action.payload;
      })
      .addCase(getAllUserResults.rejected, (state, action: any & { payload: any }) => {
        state.pending.userResults = false;
        state.errors.userResults = action.payload.message;
      });

    // // ============ GET TEST RESULT BY TEST ID AND USER ID ============ //
    builder
      .addCase(getCurrentTestResult.pending, (state) => {
        state.pending.userResult = true;
        state.errors.userResult = null;
      })
      .addCase(getCurrentTestResult.fulfilled, (state, action) => {
        state.pending.userResult = false;
        state.userResult = action.payload;
      })
      .addCase(getCurrentTestResult.rejected, (state, action: any & { payload: any }) => {
        state.pending.userResult = false;
        state.errors.userResult = action.payload.message;
      });
  },
});

const { reducer } = resultsSlice;
export default reducer;
