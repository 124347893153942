import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import React from 'react';
import { clsx as cn } from 'clsx';

import { heights } from './constants/heights';
import { palettes } from './constants/palettes';
import { rounding } from './constants/rounding';
import { sizes } from './constants/sizes';
import { HeightType } from './interfaces/HeightType';
import { PaletteType } from './interfaces/PaletteType';
import { RadiusType } from './interfaces/RadiusType';
import { SizeType } from './interfaces/SizeType';

import styles from './styles.module.scss';

interface IButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  palette?: PaletteType;
  size?: SizeType;
  radius?: RadiusType;
  height?: HeightType;
  className?: string;
  startIcon?: string;
  endIcon?: string;
  children?: React.ReactNode;
}

const Button: FC<IButtonProps> = ({
  type = 'submit',
  palette = 'primary',
  size = 'fixed',
  radius = 'regular',
  height = 'regular',
  className,
  children,
  startIcon,
  endIcon,
  ...props
}) => (
  <button
    type={type}
    className={cn(palettes[palette], sizes[size], rounding[radius], heights[height], className)}
    {...props}
  >
    {startIcon && (
      <img
        src={startIcon}
        alt="start-icon"
        width={16}
        height={16}
        className={styles.imageMarginRight}
      />
    )}
    {children}
    {endIcon && (
      <img src={endIcon} alt={endIcon} width={16} height={16} className={styles.imageMarginLeft} />
    )}
  </button>
);

export default Button;
