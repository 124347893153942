import React, { FC } from 'react';
import { StyleLabelAlign } from 'src/constants/styleLabels';

import Card from 'src/components/lips/card';
import Text from 'src/components/lips/text';

import styles from './styles.module.scss';

export interface ErrorViewerPasswordFormProps {
  error: string;
}

const ErrorViewerPasswordForm: FC<ErrorViewerPasswordFormProps> = ({ error }) => (
  <>
    <Card className={styles.cardContainer}>
      <Text align={StyleLabelAlign.Center} className={styles.labelPrefix}>
        {error}
      </Text>
    </Card>
  </>
);

export default ErrorViewerPasswordForm;
