import React from 'react';
import { useSwiper } from 'swiper/react';

import { getClassNamesSwiper } from '../swiperPicker/config';

export const SwiperPickerSlide = ({
  index,
  isMonth,
  monthIndexes = [],

  slide,
  paddingLeft,
  paddingRight,
  slides,
  selectedValue,
  isActive,
  isNext,
  isPrev,
  isVisible,
}: {
  index: number;
  isMonth: boolean;
  isYear: boolean;
  isSmall: boolean;
  monthIndexes: Array<number>;
  handleChange: (value: number) => void;
  slide: string | number;
  paddingLeft: boolean;
  paddingRight: boolean;
  slides: Array<string | number>;
  selectedValue: number;
  isActive: boolean;
  isPrev: boolean;
  isNext: boolean;
  isVisible: boolean;
}) => {
  const swiper = useSwiper();

  return (
    <div
      className={getClassNamesSwiper({
        index: isMonth ? index + 1 : index,
        data: isMonth ? monthIndexes : (slides as number[]),
        selectedValue,
        paddingLeft,
        paddingRight,
        isActive,
        isNext,
        isPrev,
        isVisible,
      })}
      onClick={() => {
        swiper.slideToLoop(index);
      }}
    >
      {slide}
    </div>
  );
};
