import React, { useEffect } from 'react';
import cn from 'classnames';
import SwiperCore, { Navigation } from 'swiper';

import { useResizeWindow } from 'src/hooks/useResizeWindow';

import { datePickerCountDays } from './components/constants/datePickerCountDays';
import { datePickerStartYear } from './components/constants/datePickerStartYear';
import DatePickerButtons from './components/datePickerButtons';
import { useSwiperPicker } from './components/helpers/useSwiperPicker';
import SwiperPicker from './components/swiperPicker';
import { generateSwiperData } from './components/swiperPicker/helpers/generateSwiperData';
import { DatePickerType } from './interfaces/DatePickerType';
import { MonthFormatType } from './interfaces/MonthFormatType';

import 'swiper/swiper.min.css';
import styles from './styles.module.scss';

SwiperCore.use([Navigation]);

interface DatepickerProps {
  setIsDatePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDatePickerOpen: boolean;
  currentDate: Date;
  type?: DatePickerType;
  language?: string;
  monthFormat?: MonthFormatType;
  onDateChange: (selectedDate: Date) => void;
  onClose?: (ref?: HTMLElement) => void;
  setIsDraggable: (value: boolean) => void;

  wrapperClassName?: string;
}

const DatePickerWithSwiper: React.FC<DatepickerProps> = ({
  currentDate,
  type = 'single',
  language = 'en',
  monthFormat = 'short',
  onDateChange,
  onClose,
  setIsDatePickerOpen,
  setIsDraggable,

  wrapperClassName,
}) => {
  const { isMobile } = useResizeWindow();
  const { monthNames, monthIndexes, generateDays, generateYears } = generateSwiperData(
    language,
    monthFormat,
    isMobile,
  );
  useEffect(() => {
    setIsDatePickerOpen(true);

    return () => setIsDatePickerOpen(false);
  }, []);
  const {
    daysSwiperRef,
    monthsSwiperRef,
    yearsSwiperRef,
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedDate,
    handleDayChange,
    handleMonthChange,
    handleYearChange,
    handleDateChange,
    handleClose,
  } = useSwiperPicker(currentDate, onClose);

  useEffect(() => {
    if (type !== 'single') {
      handleDateChange();
    }

    if (selectedDate) {
      onDateChange(new Date(selectedYear, selectedMonth - 1, selectedDay));
      if (onClose) {
        onClose();
      }
    }
  }, [selectedMonth, selectedYear, selectedDay, selectedDate]);
  return (
    <div
      className={cn(
        type === 'single' ? styles.calendarWrapper : styles.datePickerWrapper,
        wrapperClassName,
      )}
    >
      <div className={styles.datePickerHeader}>
        {`${selectedDay} ${monthNames[selectedMonth - 1]} ${selectedYear}`}
      </div>

      <div className={styles.datePickerContainer}>
        <div className={styles.selector} />
        <SwiperPicker
          isSmall={type !== 'single'}
          slides={generateDays(datePickerCountDays)}
          currentValue={selectedDay}
          swiperRef={daysSwiperRef}
          paddingLeft
          handleChange={handleDayChange}
          setIsDraggable={setIsDraggable}
        />
        <SwiperPicker
          isSmall={type !== 'single'}
          isMonth
          slides={monthNames}
          monthIndexes={monthIndexes}
          currentValue={selectedMonth}
          swiperRef={monthsSwiperRef}
          handleChange={handleMonthChange}
          setIsDraggable={setIsDraggable}
        />
        <SwiperPicker
          isSmall={type !== 'single'}
          isYear
          slides={generateYears(datePickerStartYear, new Date().getFullYear() + 5)}
          currentValue={selectedYear}
          swiperRef={yearsSwiperRef}
          paddingRight
          handleChange={handleYearChange}
          setIsDraggable={setIsDraggable}
        />
      </div>

      {type === 'single' && (
        <DatePickerButtons onDoneClick={handleDateChange} onCloseClick={handleClose} />
      )}
    </div>
  );
};

export default DatePickerWithSwiper;
