import { Routes } from 'src/components/router/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHook';
import { useModalNavigation } from 'src/hooks/useModalNavigation';
// import { useOrders } from 'src/hooks/useOrders';
import { getSignUp } from 'src/store/selectors/signUp';
import { useRegistrationMutation } from 'src/store/services/auth/auth';
import { UserWithProfiles } from 'src/store/services/profile/dto/get-user-profile.response';
// import {
//   useChooseProfileMutation,
//   useUsersProfileMutation,
// } from 'src/store/services/profile/profile';
// import { openForm, setIsFromLanding } from 'src/store/slices/maidRegistration';
// import { clearSearchSliceAuthorized } from 'src/store/slices/search';
import { setClear, setPasswordAlertError, setPasswordInfo } from 'src/store/slices/signUp';
import { useNavigate, useLocation } from 'react-router-dom';

import { IsConfirmVerifyCode } from '../../../constants/sign-up.enum';
import { transformMobileNumber } from '../../../helpers/transform-fields';
import { IFullFormikValues, ISignUpStore } from '../../../interface/sign-up.interface';
import { IPassword } from '../interface/password.interface';

export const useSubmitLastStep = () => {
  const [registration] = useRegistrationMutation();
  const { switchModal } = useModalNavigation();
  // const [getUser] = useUsersProfileMutation();
  const navigate = useNavigate();
  const location = useLocation();
  // const [chooseProfile] = useChooseProfileMutation();
  const dispatch = useAppDispatch();
  const { generalInfo, verificationInfo, addressInfo } = useAppSelector(getSignUp);
  const allForms: ISignUpStore = useAppSelector(getSignUp);
  // const { createFromCart } = useOrders();

  const onSubmit = async (value: IPassword) => {
    dispatch(setPasswordInfo(value));
    const invalidForms: string[] = [];
    const { dateOfBirth, ...restGeneralInfo } = generalInfo.fields;
    const payload: IFullFormikValues = {
      ...value,
      ...restGeneralInfo,
      ...verificationInfo.fields,
      // ...addressInfo.fields,
      mobileNumber: transformMobileNumber(verificationInfo.fields.mobileNumber),
      ...(generalInfo.fields.dateOfBirth && {
        dateOfBirth: new Date(generalInfo.fields.dateOfBirth).toISOString(),
      }),
    };

    Object.keys(allForms).forEach((el) => {
      if (
        el !== 'isSignUp' &&
        !allForms[el].confirmed &&
        el !== '_persist' &&
        el !== 'passwordInfo' &&
        el !== 'lastStep'
      ) {
        invalidForms.push(el);
      }
    });

    // if (invalidForms.length > 0) {
    //   dispatch(setPasswordAlertError(`Fill ${invalidForms.join(' ')} forms`));
    //   return;
    // }

    if (verificationInfo.alertError == IsConfirmVerifyCode.Fulfilled) {
      const res = await registration(payload);
      console.log('res', res);
      const data = (res as { data: { profileId: string } }).data;
      if (data.profileId) {
        navigate(Routes.Main);
      }

      // if (data.profileId) {
      //   try {
      //     const profilesRes = (await getUser()) as { data: UserWithProfiles };
      //     if (profilesRes.data) {
      //       const { addresses } = profilesRes.data;
      //       // await chooseProfile({ id: data.profileId, role: 'Customer' });
      //       const orderAddress = addresses.at(0);
      //       if (orderAddress) {
      //         await createFromCart(orderAddress.id);
      //       }
      //
      //       if (!location.pathname.includes(Routes.Careers)) {
      //         navigate({
      //           pathname: Routes.Customer,
      //           search: `?openMaidReg=true&slug=maidRegistration`,
      //         });
      //
      //         dispatch(setIsFromLanding(true));
      //         dispatch(openForm('photoAdding'));
      //         switchModal('maidRegistration');
      //       }
      //
      //       dispatch(clearSearchSliceAuthorized());
      //       dispatch(setClear());
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    } else {
      dispatch(setPasswordAlertError(`Confirm your mobile number`));
      return;
    }
  };

  return {
    onSubmit,
  };
};
