import { FC } from 'react';
import React from 'react';
import { useState } from 'react';
import { Popover } from '@headlessui/react';
import clsx, { clsx as cn } from 'clsx';

import calendar from 'src/assets/icons/calendar.svg';

import { convertShortDate } from 'src/utils/date/dateFormat/convertDate';

import styles from './styles.module.scss';
import DatePickerWithSwiper from '../datePickerWithSwiper';

interface IDatePickerInputProps {
  value?: string;
  id?: string;
  onChange?: (inputValue: string) => void;
  caption?: string;
  language?: string;
  onChangeForm?: (val: string, val2: string) => void;
  name?: string;
  setIsDatePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDatePickerOpen: boolean;
  setIsDraggable: (value: boolean) => void;
  isReversed?: boolean;
  calendarWrapperClassName?: string;
}

const DatePickerInput: FC<IDatePickerInputProps> = ({
  value,
  id = '',
  onChange = () => {
    console.log('here');
  },
  caption = 'Date of birth',
  language = 'en',
  onChangeForm,
  name,
  isDatePickerOpen,
  setIsDatePickerOpen,
  setIsDraggable,
  isReversed,
  calendarWrapperClassName,
}) => {
  const [date, setDate] = useState(value);

  const onChangeInput = (inputValue: Date) => {
    if (onChangeForm) {
      onChangeForm('dateOfBirth', inputValue.toString());
    }
    setDate(inputValue.toString());
    onChange(inputValue.toString());
  };

  const swiperDate = new Date(date || '2000-01-01');
  return (
    <div className={clsx(styles.inputWrapper, isReversed && styles.reversed)}>
      <span className={cn(styles.birth, styles.position)}>{caption}</span>
      <input
        name={name}
        type="text"
        id={id}
        value={date ? convertShortDate(new Date(date)) : date}
        onChange={(e) => onChangeInput(new Date(e.currentTarget.value))}
        className={cn(styles.input, isReversed && styles.reversed)}
        max={convertShortDate(new Date())}
        min={convertShortDate(new Date(new Date().setFullYear(new Date().getFullYear() - 100)))}
        readOnly
      />
      <Popover>
        {({ close }) => (
          <>
            <Popover.Button className={cn(styles.calendar, styles.position)}>
              <img src={calendar} alt="calendar" />
            </Popover.Button>

            <Popover.Panel className={styles.pickerContainer}>
              <DatePickerWithSwiper
                isDatePickerOpen={isDatePickerOpen}
                setIsDatePickerOpen={setIsDatePickerOpen}
                onDateChange={onChangeInput}
                onClose={close}
                currentDate={swiperDate}
                language={language}
                setIsDraggable={setIsDraggable}
                wrapperClassName={clsx(
                  isReversed && styles.wrapperReversed,
                  calendarWrapperClassName,
                )}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};
export default DatePickerInput;
