import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleLabelSize } from 'src/constants/styleLabels';
import { Form, useFormikContext } from 'formik';

import Button from 'src/components/lips/button';
import { initFormModelSignIn } from '../form-model/init-values-sign-in';

import { SignInFields } from './SignInFields';

import styles from '../styles.module.scss';

interface IMainScreenSignInProps {
  isValid: boolean;
  createNewAccountHandler?: VoidFunction;
  isError?: boolean;
  forgotPasswordHandler?: VoidFunction;
  isReversed?: boolean;
}

export const MainScreenSignIn: FC<IMainScreenSignInProps> = ({
  isValid,
  isError,
  isReversed,
  createNewAccountHandler = () => {
    console.log('here');
  },
}) => {
  const { setFieldError } = useFormikContext();
  const { t } = useTranslation();
  useEffect(() => {
    if (isError) {
      setFieldError('identifier', t('signInModal.invalidData') || '');
    }
  }, [isError]);

  return (
    <div className={styles.MainScreenSignInContainer}>
      <h1>We're happy to see you again!</h1>
      <Form className={styles.conainerForm}>
        <SignInFields isReversed={isReversed} fields={initFormModelSignIn} />
        <Button
          type="submit"
          size={StyleLabelSize.Fill}
          className={styles.button}
          disabled={!isValid}
        >
          {t('signInModal.submit')}
        </Button>
        <div className={styles.goToSignUp}>
          <p>Don't have an account?</p>
          <Button className={styles.link} onClick={createNewAccountHandler}>
            {t('signUpModal.signUp')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
