import { createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../../api/api";
import { AllResultDto } from "./types/all-results.dto";
import { IUserResults } from "./types/user-results.dto";
import { CurrentTestResultDto } from "./types/current-test-result.dto";

// ============ GET ALL RESULTS ============ //
export const getAllResults = createAsyncThunk<AllResultDto[]>(
  "tests/all/results",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get("/tests/all/results");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

//================ GET RESULTS BY USER ID ================//
export const getAllUserResults = createAsyncThunk<IUserResults[], string>(
  "tests/user-results/results",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/user-results/${userId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET TEST RESULT BY TEST ID AND USER ID ============ //
export const getCurrentTestResult = createAsyncThunk<
  CurrentTestResultDto,
  { testId: string; userId: string }
>("tests/:testId/:userId/current-result", async ({ testId, userId }, { rejectWithValue }) => {
  try {
    const response = await $api.get(`/tests/${testId}/${userId}/current-result`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message as string);
  }
});
