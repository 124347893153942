import { createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../../api/api";
import { ConnectionDto } from "../tests/types/connections.dto";

// ============ GET CREATE CONNECTION ============ //
export const getCreateConnection = createAsyncThunk<
  ConnectionDto[],
  { testId: string; lendingId: number }
>(
  "tests/connection/create",
  async (data: { testId: string; lendingId: number }, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/create-connect/${data.testId}/${data.lendingId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET DROP CONNECTION ============ //
export const getDropConnection = createAsyncThunk<
  ConnectionDto[],
  { testId: string; lendingId: number }
>(
  "tests/connection/drop",
  async (data: { testId: string; lendingId: number }, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/drop-connect/${data.testId}/${data.lendingId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ GET ALL CONNECTIONS ============ //
export const getAllConnections = createAsyncThunk(
  "tests/all/connections",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/tests/connections`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);
