import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-advanced-click-away';
import {
  CountryData,
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
} from 'react-international-phone';
import { ParsedCountry } from 'react-international-phone';
import Sheet from 'react-modal-sheet';
import { clsx as cn } from 'clsx';

import { FunctionType } from 'src/types/FunctionType';

import styles from './styles.module.scss';

export const CountrySelectorDropdown = ({
  className,
  listItemClassName,
  listItemFlagClassName,
  listItemCountryNameClassName,
  listItemDialCodeClassName,
  show,
  selectedCountry,
  countries = defaultCountries,
  onSelect,
  onClose,
  setSearchValue,
  searchValue,
  setIsOpenSelect,
}: {
  className?: string;
  listItemClassName?: string;
  listItemFlagClassName?: string;
  listItemCountryNameClassName?: string;
  listItemDialCodeClassName?: string;
  show: boolean;
  dialCodePrefix?: string;
  selectedCountry: CountryIso2;
  countries: CountryData[];
  setIsOpenSelect: (country: boolean) => void;
  onSelect: (country: ParsedCountry) => void;
  onClose: () => void;
  setSearchValue: FunctionType;
  searchValue: string;
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  useEffect(() => {
    setSearchValue('');
    setIsInputFocused(false);
  }, [show]);
  return (
    <>
      {show && (
        <ClickAwayListener onClickAway={onClose}>
          <div
            className={cn(
              styles.countrySelectorContainer,
              isInputFocused && styles.countrySelectorContainerFocused,
            )}
          >
            <input
              onBlur={() => setIsInputFocused(false)}
              onFocus={() => setIsInputFocused(true)}
              autoCorrect="off"
              role="textbox"
              type="search"
              spellCheck="false"
              autoComplete="off"
              dir="ltr"
              aria-haspopup="listbox"
              aria-autocomplete="list"
              aria-expanded="false"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search country..."
              className={styles.countriesFilter}
            />
            <ul className={cn(styles.selector, className)}>
              <Sheet.Scroller>
                {countries.map((country) => {
                  const [name, iso2, dialCode] = country;
                  return (
                    <li
                      key={iso2}
                      onTouchStart={() => {
                        onSelect(parseCountry(country));
                      }}
                      onTouchEnd={(e) => {
                        e.preventDefault();
                      }}
                      onClick={() => {
                        onSelect(parseCountry(country));
                        setIsOpenSelect(false);
                      }}
                      className={cn(
                        styles.listItem,
                        iso2 === selectedCountry && styles.selected,
                        listItemClassName,
                      )}
                    >
                      <FlagImage
                        className={cn(styles.flag, listItemFlagClassName)}
                        iso2={iso2}
                        size="30px"
                      />
                      <span
                        className={cn(styles.listItemCountryName, listItemCountryNameClassName)}
                      >
                        {name}
                      </span>
                      <span className={cn(styles.listItemDialCode, listItemDialCodeClassName)}>
                        {dialCode}
                      </span>
                    </li>
                  );
                })}
              </Sheet.Scroller>
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};
