import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
  const navigate = useNavigate();

  const handleClickSignIn = () => {
    navigate('/signIn');
  };

  const handleClickSignUp = () => {
    navigate('/signUp');
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {/*<div className={styles.ellipse103} />*/}
        <h1>Welcome!</h1>
      </div>
      <div className={styles.mainBlock}>
        <p>Do you want to get started?</p>
        <button onClick={handleClickSignIn}>Sign in</button>
        <button onClick={handleClickSignUp}>Create account</button>
      </div>
      <div className={styles.rightRectBlur} />
      <div className={styles.bottomRectBlur} />
    </div>
  );
}
