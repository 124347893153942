import type { FC, PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import { Tab } from '@headlessui/react';

import styles from './styles.module.scss';

interface ChipsGroupProps extends PropsWithChildren {
  selectedIndex?: number;
  setSelectedIndex: (val: number) => void;
  chips: ReactNode;
}

const ChipsGroup: FC<ChipsGroupProps> = ({ children, selectedIndex, setSelectedIndex, chips }) => (
  <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
    {/*<Tab.List className={styles.chipsWrapper}>{chips}</Tab.List>*/}
    <Tab.Panels className={styles.chipsWrapper}>{children}</Tab.Panels>
  </Tab.Group>
);

export default ChipsGroup;
