import React, { FC } from 'react';
// @ts-ignore
import type { Props } from 'react-input-mask';
// @ts-ignore
import InputMask from 'react-input-mask';

import withWrapper from '../helpers/hocs/withWrapper';
import { HeightType } from '../interfaces/HeightType';
import { PaletteType } from '../interfaces/PaletteType';

type MaskInputType = {
  mask?: string | (string | RegExp)[];
  containerClassNames?: string;
  inputWrapperClassName?: string;
  labelClassName?: string;
  palette?: PaletteType;
  height?: HeightType;
};

export type MaskInputProps = MaskInputType & Omit<Props, 'mask'>;

const MaskInput: FC<MaskInputProps> = ({ mask = '', ...props }) => (
  <InputMask maskPlaceholder={' '} mask={mask} {...props} />
);

export default withWrapper(MaskInput);
