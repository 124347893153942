import { FC } from 'react';
import React from 'react';
import { Form, Formik } from 'formik';

import { useAppDispatch } from 'src/hooks/reduxHook';
import { setGeneralInfo } from 'src/store/slices/signUp';

import GeneralInfoField from './components/GeneralInfoField';
import { generalInfoFormSchema } from './form-model/general-info-schema';
import { IGeneralInfo } from './interface/general-info.interface';

import styles from './styles.module.scss';

interface ISignUpProps {
  initialValues: IGeneralInfo;
  setActiveStep: (val: number) => void;
  setIsDraggable: (value: boolean) => void;
  isReversed?: boolean;
  openSignInHandler?: () => void;
}

const GeneralInfoForm: FC<ISignUpProps> = ({
  initialValues,
  setActiveStep,
  setIsDraggable,
  isReversed,
  openSignInHandler,
}) => {
  const dispatch = useAppDispatch();

  const onSubmit = (value: IGeneralInfo) => {
    dispatch(setGeneralInfo(value));
    setActiveStep(1);
  };
  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={generalInfoFormSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <div className={styles.mainContainer}>
            <GeneralInfoField
              isReversed={isReversed}
              setIsDraggable={setIsDraggable}
              openSignInHandler={openSignInHandler}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralInfoForm;
