import type { FC } from 'react';
import React from 'react';
import { Fragment } from 'react';
import { Tab } from '@headlessui/react';

import { getClassNames } from './config';
import { StyleLabelAlt } from 'src/constants/styleLabels';

export interface FormChipProps {
  icon: string;
  text: string;
  error: boolean;
  customSelectClass?: string;
}

const FormChip: FC<FormChipProps> = ({ icon, text, error, customSelectClass = '' }) => (
  <Tab as={Fragment}>
    {({ selected }) => {
      const { containerClassNames, iconWrapperClassNames, iconClassNames } = getClassNames({
        selected,
        error,
        customSelectClass,
      });
      return (
        <div className={containerClassNames}>
          <div className={iconWrapperClassNames}>
            {/*<Icon alt={StyleLabelAlt.Logo} className={iconClassNames} />*/}
            <img src={icon} alt={StyleLabelAlt.Logo} />
          </div>
          {selected && <span>{text}</span>}
        </div>
      );
    }}
  </Tab>
);

export default FormChip;
