import { createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../../api/api";
import { CategoryDto } from "./types/categories.dto";

// ============ GET ALL CATEGORIES ============ //
export const getAllCategories = createAsyncThunk<CategoryDto[]>(
  "users/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get("/tests/categories");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);

// ============ POST CREATE CATEGORY ============ //
export const createCategory = createAsyncThunk<CategoryDto, string>(
  "users/createCategory",
  async (name: string, { rejectWithValue }) => {
    try {
      const response = await $api.post("/tests/category/create", name);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message as string);
    }
  }
);
