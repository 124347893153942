import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleLabelPalette } from 'src/constants/styleLabels';
import type { FieldProps } from 'formik';
import { Field, useFormikContext } from 'formik';

import Button from 'src/components/lips/button';
import CustomInput from 'src/components/lips/customInput';

import { IPassword } from '../../interface/password.interface';

import styles from './styles.module.scss';

const PasswordField = ({ isReversed }: { isReversed?: boolean }) => {
  const { values, isValid } = useFormikContext<IPassword>();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Field name="password">
        {({
          field,
          meta,
        }: FieldProps<{
          password: string;
        }>) => (
          <CustomInput
            variant="password"
            label={t('signUpModal.password')}
            key="password"
            name="password"
            id="password"
            value={values.password}
            error={t(meta.error as string) || ''}
            onChange={field.onChange}
            className={styles.input}
            isReversed={isReversed}
            noBorderBottom
            borderBottomLabel
          />
        )}
      </Field>
      <Field name="passwordConfirmation">
        {({
          field,
          meta,
        }: FieldProps<{
          passwordConfirmation: string;
        }>) => (
          <CustomInput
            variant="password"
            label={t('signUpModal.confirmPassword')}
            key="passwordConfirmation"
            name="passwordConfirmation"
            id="passwordConfirmation"
            value={values.passwordConfirmation}
            error={t(meta.error as string) || ''}
            onChange={field.onChange}
            className={styles.input}
            noBorderBottom
            borderBottomLabel
            isReversed={isReversed}
          />
        )}
      </Field>
      <div className={styles.buttonWrapper}>
        <Button
          type="submit"
          palette={StyleLabelPalette.Primary}
          disabled={!isValid}
          className={styles.btnCreate}
        >
          {t('signUpModal.createAccount')}
        </Button>
      </div>
    </div>
  );
};

export default PasswordField;
