import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleLabelAlign } from 'src/constants/styleLabels';

import Card from 'src/components/lips/card';
import Text from 'src/components/lips/text';
import Loader from 'src/components/lips/loader';
import { IsConfirmVerifyCode } from 'src/components/registration/forgotPassword/forms/confirm-form/constants/IsConfirmVerifyCode.enum';

import useErrorMessageVerification from './hooks/error-massages-verification.hook';
import { getCardClassNames } from './config';

import styles from './styles.module.scss';

export interface IErrorMessage {
  main: string;
  prefix: string;
  isConfirmVerifyCode: IsConfirmVerifyCode;
}
export interface ErrorViewerVerificationFormProps {
  error: string;
}

const ErrorViewerVerificationForm: FC<ErrorViewerVerificationFormProps> = ({ error }) => {
  const [errorMessage] = useErrorMessageVerification(error);

  const { t } = useTranslation();

  const { cardClassName } = getCardClassNames({
    error:
      errorMessage.isConfirmVerifyCode === IsConfirmVerifyCode.Reject ||
      errorMessage.isConfirmVerifyCode === IsConfirmVerifyCode.ConnectionError,
  });

  return (
    <>
      {errorMessage.main && (
        <Card className={cardClassName}>
          <Text align={StyleLabelAlign.Center} className={styles.label}>
            {t(errorMessage.main)}
            {t(errorMessage.main) === t('signUpModal.loading') && <Loader />}
          </Text>
        </Card>
      )}
    </>
  );
};

export default ErrorViewerVerificationForm;
