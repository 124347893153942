export enum PathRequest {
  Registration = 'api/auth/registration',
  Login = 'api/auth/login',
  Update = 'auth',
  ResetPassword = 'auth/reset-password',
  ForgotPassword = 'auth/forgot-password',
  GetVerifyCode = 'public/api/whatsapp/send-verify-data',
  getSignUpInfo = 'public/api/whatsapp/get-sing-up-info',
  GetEmailVerifyCode = 'auth/email-verify-code',
  GetVerifyCodeMobileNumber = 'auth/code-profile-mobile',
  GetUserProfile = 'auth/user-with-profiles',
  CreateProfile = 'profiles',
  BlackList = 'profiles/blacklist',
  Order = 'profiles/order',
  OrderCheckUnsuited = 'profiles/order-check-unsuited',
  Temporary = 'profiles/temporary',
  Cart = 'profiles/cart',
  AddOrderAbsence = 'profiles/order/absence',
  AddProfileReview = 'profiles/review/profile',
  AddChildrenReview = 'profiles/review/children',
  GetReviewByAuthor = 'profiles/review/author',
  GetReviewByRecipient = 'profiles/review/recipient',
  GetReviewForProfileCard = 'profiles/review/card',
  GetProfileCard = 'profiles/card',
  SearchHousekeeper = 'profiles/search',
  SearchNearest = 'profiles/nearest',
  SearchNearestSSR = 'profiles/nearest-ssr',
  AddPhoto = 'profiles/add-photo',
  SetAvatar = 'profiles/set-avatar',
  DeletePhoto = 'profiles/delete-photo',
  SearchHousekeeperDto = 'profiles/search-dto',
  ChooseProfile = 'profiles/current',
  EditSecretKey = 'auth/secret-key',
  GetNotifications = 'notifications',
  ReadAllNotifications = 'notifications/read-all',
  ChangeNotificationsLanguage = 'notifications/change-lang',
  ReadNotification = 'notifications/read',
  TwoFactor = 'auth/two-factor',
  TwoFactorNotConfirmed = 'auth/two-factor-not-confirmed',
  TwoFactorConfirm = 'auth/two-factor-confirm',
  GenerateSecretKeyOptions = 'auth/generate-secret-key-options',
  GenerateAuthorizationSecretKeyOptions = 'auth/generate-authorization-secret-key-options',
  GenerateAuthorizationSecretKeyOptionsAuth = 'auth/generate-authorization-secret-key-options-auth',
  GenerateAuthorizationSecretKeyOptionsAuthAll = 'auth/generate-authorization-secret-key-options-auth-all',
  VerifyRegistrationSecret = 'auth/verify-registration-secret',
  VerifyAuthorizationSecret = 'auth/verify-authorization-secret',
  VerifyAuthorizationSecretLogin = 'auth/verify-authorization-secret-login',
  GetSecretKeys = 'auth/secret-keys',
  SetSecretKeyStatus = 'auth/set-secret-key-status',
  Authenticate = 'profiles/authenticate',
  AdminLoginUser = 'auth/admin/login-user',
  Promotions = 'promotions',
  Fee = 'promotions/fee',
  AddWaiter = 'profiles/add-waiter',
  AddWaiterAuth = 'profiles/add-waiter-auth',
  RemoveBiometrics = 'auth/remove-biometrics',

  UpdateProfile = 'profiles',
  GetCountryCode = 'profiles/get-country-code',
  GetIpAddress = 'https://www.cloudflare.com/cdn-cgi/trace',
  CheckUserByEmailPhone = 'auth/check-user-by-email-phone',
  TempHousekeeperSignUp = 'profiles/housekeeper-signup',
  ChangePassword = 'auth/change-password',
  ConfirmConditions = 'auth/policy/confirm',
  GetAvailiableUsersAddresses = 'profiles/get-availiable-users-addresses',
}
