import { generateClassName, IClassNameItem } from 'src/utils/helpers/generateClassName';

import styles from './styles.module.scss';

interface IGetClassNames {
  error?: string;
  text?: string | null;
  noHeading?: boolean;
  noBackground?: boolean;
  noBorderBottom?: boolean;
  borderBottomLabel?: boolean;
  touched?: boolean;
  label?: string;
}

export const getClassNames = ({
  error,
  label,
  text,
  noHeading = false,
  noBackground = false,
  noBorderBottom = false,
  touched = false,
  borderBottomLabel = false,
}: IGetClassNames) => {
  const labelTextClassNames: IClassNameItem[] = [
    {
      className: styles.labelText,
      needDisplay: true,
    },
    {
      className: styles.activeLabelText,
      needDisplay: !!text,
    },
    {
      className: styles.noHeadingLabel,
      needDisplay: !!text && noHeading,
    },
    {
      className: styles.default,
      needDisplay: !noBorderBottom || borderBottomLabel,
    },
  ];
  const containerClassNames: IClassNameItem[] = [
    {
      className: styles.container,
      needDisplay: true,
    },
    {
      className: styles.noHeading,
      needDisplay: !!text && noHeading,
    },
    {
      className: styles.containerError,
      needDisplay: touched && !!error,
    },
    {
      className: styles.boxShadow,
      needDisplay: !noBorderBottom,
    },
    {
      className: styles.line,
      needDisplay: !!label && noBorderBottom,
    },
    {
      className: styles.lineText,
      needDisplay: borderBottomLabel,
    },
    {
      className: styles.noBorderBottom,
      needDisplay: !!text && noBorderBottom,
    },
    {
      className: styles.wrapperDefault,
      needDisplay: noBackground,
    },
  ];

  return {
    labelTextClassName: generateClassName(labelTextClassNames),
    containerClassName: generateClassName(containerClassNames),
  };
};
