// ========================== redux ==========================
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ========================== types ==========================
import { UserUpdateDto } from "./types/user-details-update.type";
import { UserDto } from "./types/user-dto.type";
import { UserState } from "./types/user-state.type";

// ========================== store ==========================
import {
  getUsers,
  getAllUsers,
  getAdminInfo,
  updateUserInfo,
  deleteUser,
  assignRole,
  getUserInfoById,
} from "./users.actions";

const initialState: UserState = {
  users: [],
  adminInfo: null,
  userInfo: null,
  newRole: null,
  pending: {
    users: false,
    userInfo: false,
    adminInfo: false,
  },
  errors: {
    users: null,
    userInfo: null,
    adminInfo: null,
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ============ GET USERS ============ //
      .addCase(getUsers.pending, (state) => {
        state.pending.users = true;
        state.errors.users = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.pending.users = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action: any & { payload: any }) => {
        state.pending.users = false;
        state.errors.users = action.payload.message;
      });

    // ============ GET ALL USERS ============ //
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.pending.users = true;
        state.errors.users = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.pending.users = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action: any & { payload: any }) => {
        state.pending.users = false;
        state.errors.users = action.payload.message;
      });

    // ============ GET ADMIN INFO ============ //
    builder
      .addCase(getAdminInfo.pending, (state) => {
        state.pending.adminInfo = true;
        state.errors.adminInfo = null;
      })
      .addCase(getAdminInfo.fulfilled, (state, action) => {
        state.pending.adminInfo = false;
        state.adminInfo = action.payload;
      })
      .addCase(getAdminInfo.rejected, (state, action: any & { payload: any }) => {
        state.pending.adminInfo = false;
        state.errors.adminInfo = action.payload.message;
      });

    // ============ GET USER INFO BY ID ============ //
    builder
      .addCase(getUserInfoById.pending, (state) => {
        state.pending.userInfo = true;
        state.errors.userInfo = null;
      })
      .addCase(getUserInfoById.fulfilled, (state, action) => {
        state.pending.userInfo = false;
        state.userInfo = action.payload;
      })
      .addCase(getUserInfoById.rejected, (state, action: any & { payload: any }) => {
        state.pending.userInfo = false;
        state.errors.userInfo = action.payload.message;
      });

    // ============ UPDATE USER INFO ============ //
    builder
      .addCase(updateUserInfo.pending, (state) => {
        state.pending.userInfo = true;
        state.errors.userInfo = null;
      })
      .addCase(updateUserInfo.fulfilled, (state, action: PayloadAction<UserUpdateDto>) => {
        const users = state.users.filter((user: UserDto) => user.id !== action.payload.id);
        const { details, ...user } = action.payload;

        users.push(user);
        state.users = users;
        if (!state.userInfo) return;
        state.userInfo = {
          ...state.userInfo,
          ...details,
        };
      })
      .addCase(updateUserInfo.rejected, (state, action: any & { payload: any }) => {
        state.pending.userInfo = false;
        state.errors.userInfo = action.payload.message;
      });

    // ============ DELETE USER ============ //
    builder
      .addCase(deleteUser.pending, (state) => {
        state.pending.users = true;
        state.errors.users = null;
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<UserUpdateDto>) => {
        const users = state.users.filter((user: UserDto) => user.id !== action.payload.id);
        const { details, ...user } = action.payload;

        users.push(user);
        state.users = users;
        if (!state.userInfo) return;
        state.userInfo = {
          ...state.userInfo,
          ...details,
        };
      })
      .addCase(deleteUser.rejected, (state, action: any & { payload: any }) => {
        state.pending.users = false;
        state.errors.users = action.payload.message;
      });

    // ============ ASSIGN ROLE ============ //
    builder
      .addCase(assignRole.pending, (state) => {
        state.pending.users = true;
        state.errors.users = null;
      })
      .addCase(assignRole.fulfilled, (state, action) => {
        state.newRole = action.payload;
      })
      .addCase(assignRole.rejected, (state, action: any & { payload: any }) => {
        state.pending.users = false;
        state.errors.users = action.payload.message;
      });
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
