import { RoleType } from 'src/types/RoleType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IAuthenticationState {
  role: RoleType;
}

const initialState: IAuthenticationState = {
  role: 'Guest',
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setRole: (state, { payload }: PayloadAction<RoleType>) => {
      state.role = payload;
    },
  },
});

export const { setRole } = authenticationSlice.actions;

export default authenticationSlice.reducer;
