// ========================== redux ==========================
import { createSlice } from "@reduxjs/toolkit";

// ========================== types ==========================

// ========================== store ==========================
import {
  createQuestion,
  createTest,
  deleteQuestionById,
  getAllLendings,
  getAllTests,
  getAnswerById,
  getFullTest,
  getLendingById,
  getQuestionById,
  getSnapshotById,
  patchUpdateQuestion,
  postUpdateTest,
} from "./tests.actions";
import { TestState } from "./types/tests-state.type";

const initialState: TestState = {
  currentTest: null,
  allTests: [],
  allLendings: [],
  fullTest: null,
  questionWithAnswers: null,
  answer: null,
  fullLending: null,
  pending: {
    fullLending: false,
    answer: false,
    questionWithAnswers: false,
    fullTest: false,
    allLendings: false,
    allTests: false,
    currentTest: false,
  },
  errors: {
    fullLending: null,
    answer: null,
    questionWithAnswers: null,
    fullTest: null,
    allLendings: null,
    allTests: null,
    currentTest: null,
  },
};

export const testsSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ============ GET TEST BY ID ============ //
    builder
      .addCase(getSnapshotById.pending, (state) => {
        state.pending.currentTest = true;
        state.errors.currentTest = null;
      })
      .addCase(getSnapshotById.fulfilled, (state, action) => {
        state.pending.currentTest = false;
        state.currentTest = action.payload;
      })
      .addCase(getSnapshotById.rejected, (state, action: any & { payload: any }) => {
        state.pending.currentTest = false;
        state.errors.currentTest = action.payload.message;
      });

    // ============ GET ALL TESTS ============ //
    builder
      .addCase(getAllTests.pending, (state) => {
        state.pending.allTests = true;
        state.errors.allTests = null;
      })
      .addCase(getAllTests.fulfilled, (state, action) => {
        state.pending.allTests = false;
        state.allTests = action.payload;
      })
      .addCase(getAllTests.rejected, (state, action: any & { payload: any }) => {
        state.pending.allTests = false;
        state.errors.allTests = action.payload.message;
      });

    // ============ GET ALL LENDINGS ============ //
    builder
      .addCase(getAllLendings.pending, (state) => {
        state.pending.allLendings = true;
        state.errors.allLendings = null;
      })
      .addCase(getAllLendings.fulfilled, (state, action) => {
        state.pending.allLendings = false;
        state.allLendings = action.payload;
      })
      .addCase(getAllLendings.rejected, (state, action: any & { payload: any }) => {
        state.pending.allLendings = false;
        state.errors.allLendings = action.payload.message;
      });

    // ============ UPDATE TEST ============ //
    builder
      .addCase(postUpdateTest.pending, (state) => {
        state.pending.allTests = true;
        state.errors.allTests = null;
      })
      .addCase(postUpdateTest.fulfilled, (state, action) => {
        state.pending.allTests = false;
        state.allTests = action.payload;
      })
      .addCase(postUpdateTest.rejected, (state, action: any & { payload: any }) => {
        state.pending.allTests = false;
        state.errors.allTests = action.payload.message;
      });
    // ============ CREATE QUESTION ============ //
    builder
      .addCase(createQuestion.pending, (state) => {
        state.pending.fullTest = true;
        state.errors.fullTest = null;
      })
      .addCase(createQuestion.fulfilled, (state, action) => {
        state.pending.fullTest = false;
        state.fullTest = action.payload;
        console.log(state.fullTest);
      })
      .addCase(createQuestion.rejected, (state, action: any & { payload: any }) => {
        state.pending.fullTest = false;
        state.errors.fullTest = action.payload.message;
      });
    // ============ CREATE TEST ============ //
    builder
      .addCase(createTest.pending, (state) => {
        state.pending.allTests = true;
        state.errors.allTests = null;
      })
      .addCase(createTest.fulfilled, (state, action) => {
        state.pending.allTests = false;
        state.allTests = action.payload;
      })
      .addCase(createTest.rejected, (state, action: any & { payload: any }) => {
        state.pending.allTests = false;
        state.errors.allTests = action.payload.message;
      });

    // ============ DELETE QUESTION ============ //
    builder
      .addCase(deleteQuestionById.pending, (state) => {
        state.pending.fullTest = true;
        state.errors.fullTest = null;
      })
      .addCase(deleteQuestionById.fulfilled, (state, action) => {
        state.pending.fullTest = false;
        state.fullTest = action.payload;
      })
      .addCase(deleteQuestionById.rejected, (state, action: any & { payload: any }) => {
        state.pending.fullTest = false;
        state.errors.fullTest = action.payload.message;
      });

    // ============ GET FULL TEST ============ //
    builder
      .addCase(getFullTest.pending, (state) => {
        state.pending.fullTest = true;
        state.errors.fullTest = null;
      })
      .addCase(getFullTest.fulfilled, (state, action) => {
        state.pending.fullTest = false;
        state.fullTest = action.payload;
      })
      .addCase(getFullTest.rejected, (state, action: any & { payload: any }) => {
        state.pending.fullTest = false;
        state.errors.fullTest = action.payload.message;
      });

    // ============ UPDATE QUESTION ============ //
    builder
      .addCase(patchUpdateQuestion.pending, (state) => {
        state.pending.questionWithAnswers = true;
        state.errors.questionWithAnswers = null;
      })
      .addCase(patchUpdateQuestion.fulfilled, (state, action) => {
        state.pending.questionWithAnswers = false;
        state.questionWithAnswers = action.payload;
      })
      .addCase(patchUpdateQuestion.rejected, (state, action: any & { payload: any }) => {
        state.pending.questionWithAnswers = false;
        state.errors.questionWithAnswers = action.payload.message;
      });

    // ============ GET QUESTION BY ID ============ //
    builder
      .addCase(getQuestionById.pending, (state) => {
        state.pending.questionWithAnswers = true;
        state.errors.questionWithAnswers = null;
      })
      .addCase(getQuestionById.fulfilled, (state, action) => {
        state.pending.questionWithAnswers = false;
        state.questionWithAnswers = action.payload;
      })
      .addCase(getQuestionById.rejected, (state, action: any & { payload: any }) => {
        state.pending.questionWithAnswers = false;
        state.errors.questionWithAnswers = action.payload.message;
      });

    // ============ GET ANSWER BY ID ============ //
    builder
      .addCase(getAnswerById.pending, (state) => {
        state.pending.answer = true;
        state.errors.answer = null;
      })
      .addCase(getAnswerById.fulfilled, (state, action) => {
        state.pending.answer = false;
        state.answer = action.payload;
      })
      .addCase(getAnswerById.rejected, (state, action: any & { payload: any }) => {
        state.pending.answer = false;
        state.errors.answer = action.payload.message;
      });

    // ============ GET LENDING BY ID ============ //
    builder
      .addCase(getLendingById.pending, (state) => {
        state.pending.fullLending = true;
        state.errors.fullLending = null;
      })
      .addCase(getLendingById.fulfilled, (state, action) => {
        state.pending.fullLending = false;
        state.fullLending = action.payload;
      })
      .addCase(getLendingById.rejected, (state, action: any & { payload: any }) => {
        state.pending.fullLending = false;
        state.errors.fullLending = action.payload.message;
      });
  },
});

const { reducer } = testsSlice;
export default reducer;
