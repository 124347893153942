import { forwardRef } from 'react';

import { InputConfigProps, inputTypesConfig } from './config';

interface ICustomInputProps {
  variant: 'default' | 'password' | 'mask' | 'editable';
}

const CustomInput = forwardRef(
  ({ variant, ...props }: ICustomInputProps & InputConfigProps, ref) =>
    inputTypesConfig({ ...props, ref })[variant],
);
CustomInput.displayName = 'CustomInput';
export default CustomInput;
